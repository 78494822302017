import * as constants from "./constants/index";
import api from "./fetch-data/api";

export const handleLogout = async () => {
  const response = await api.post(constants.LOGOUT_ENDPOINT);

  if (response.status === 200) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("tokenExpirationTime");
    window.location.reload();
  }
};

const accessToken = localStorage.getItem("accessToken");
export const userID = accessToken ? accessToken : handleLogout;
