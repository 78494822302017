import { useCallback, useEffect, useState, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { useParams, Link } from "react-router-dom";
import "./index.css";
import {
  Edit as EditIcon,
  Spellcheck as SpellcheckIcon,
  ShortText as ShortTextIcon,
  FormatAlignJustify as FormatAlignJustifyIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import QuillMarkdown from "quilljs-markdown";
import "quilljs-markdown/dist/quilljs-markdown-common-style.css";
import { marked } from "marked";
import htmlDocx from "html-docx-js/dist/html-docx";
import * as constants from "../../utils/constants/index";
import api from "../../utils/fetch-data/api";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Loading from "../loading/Loading";
import ChartBox from "../chart-box/ChartBox";
import { Box, Grid, Typography } from "@mui/material";
import {
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
  serverTimestamp,
  doc,
} from "firebase/firestore";
import { db } from "../../config/firebase";
import { toast } from "react-toastify";
import moment from "moment";
import Delta from "quill-delta";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DocumentCharBox from "../chart-box/DocumentCharBox";
import axios from "axios";
import InsertToDocument from "../chart-box/InsertToDocument";
import { v4 as uuidV4 } from "uuid";
import { handleLogout } from "../../utils/getAccessToken";
import CollaboratorsAvatars from "../Elements/CollaboratorsAvatars";
import { Helmet, HelmetProvider } from "react-helmet-async";

/**
 * Uploads an image to Google Cloud Storage using a pre-signed URL.
 *
 * @param {File} file The image file to upload.
 * @returns {Promise<string>} A promise that resolves with the public URL of the uploaded image.
 */
const uploadImageToGCS = async (file) => {
  try {
    // Get pre-signed URL from the backend
    const response = await api.get(constants.UPLOAD_URL_ENDPOINT, {
      params: {
        fileName: file.name,
        fileType: file.type,
      },
    });

    const { uploadUrl, publicUrl } = response.data;

    if (!uploadUrl) {
      throw new Error("Upload URL is undefined");
    }

    toast.info(`Please wait its uploading your image `);

    // Upload the file to Google Cloud Storage
    await axios.put(uploadUrl, file, {
      headers: {
        "Content-Type": file.type,
        "Content-Disposition": "inline",
      },
      onUploadProgress: (ProgressEvent) => {
        const percentCompleted = Math.round(
          (ProgressEvent.loaded * 100) / ProgressEvent.total
        );
        toast.info(`Uploading image... ${percentCompleted}%`);
      },
    });

    return publicUrl;
  } catch (error) {
    toast.error(
      "There was an error uploading your image please try again later"
    );
  }
};

// Quill toolbar options
const TOOLBAR_OPTIONS = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  ["link", "image", "video", "formula"],
  [{ header: 1 }, { header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],
  [{ size: ["small", false, "large", "huge"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
  ["clean"],
];

// Quill editor options
const options = {
  theme: "snow",
  modules: {
    toolbar: TOOLBAR_OPTIONS,
    markdown: {},
  },
};

/**
 * Creates a debounced function.
 *
 * @param {Function} func The function to debounce.
 * @param {number} delay The delay in milliseconds.
 * @returns {Function} The debounced function.
 */

/**
 * The TextEditor component.
 *
 * @returns {JSX.Element} The TextEditor component.
 */
export default function TextEditor() {
  // State variables
  const newDocumentId = uuidV4();
  const { id: documentId, title: noteTitle, type, mode, action } = useParams();
  const propTittle = decodeURIComponent(noteTitle);
  const [quill, setQuill] = useState();
  const [title, setTitle] = useState(propTittle);
  const [geminiResponse, setGeminiResponse] = useState(null);
  const [askAIVisible, setAskAIVisible] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [idChecked, setIdChecked] = useState(false);
  const [currentUserEditing, setCurrentUserEditing] = useState(null);
  const [collaboratorsState, setCollaboratorsState] = useState([]);
  const [lastSavedContent, setLastSavedContent] = useState(null);
  const [typingUser, setTypingUser] = useState(null);

  // Refs
  const savedSelection = useRef(null);

  // User authentication data
  const accessToken = localStorage.getItem("accessToken");
  const userID = accessToken ? accessToken : handleLogout;
  const storedUserData = localStorage.getItem("userData");
  const userData = storedUserData ? JSON.parse(storedUserData) : handleLogout;

  // Firestore document reference
  const documentRef = doc(db, "document", documentId);

  /**
   * Loads document data from Firestore.
   *
   * @param {DocumentSnapshot} querySnapshot The document snapshot.
   */
  const loadDocumentData = (querySnapshot) => {
    const data = querySnapshot.data().data;
    let delta = new Delta();
    if (data) {
      try {
        delta = new Delta(JSON.parse(data));
      } catch (jsonError) {
        toast.error("Error parsing JSON data:" || jsonError);
      }
    }
    quill.setContents(delta);
    quill.enable();
    setTitle(querySnapshot.data().title || propTittle);
    setIdChecked(true);
    setIsLoading(false);
    setLastSavedContent(delta);
  };

  // Load the document data
  useEffect(() => {
    if (quill == null && !idChecked) return;

    const findOrCreate = async () => {
      try {
        const querySnapshot = await getDoc(documentRef);

        if (querySnapshot.exists()) {
          // Document exists
          const existingCreator = querySnapshot.data().creator;
          if (existingCreator === userID && mode !== "collaboration") {
            // User is the creator but not in collaboration mode - Load the document
            loadDocumentData(querySnapshot);
          } else if (mode === "collaboration") {
            // Check if user is already a collaborator
            if (
              querySnapshot
                .data()
                .collaborators?.some(
                  (collaborator) => collaborator.id === userID
                )
            ) {
              // User is already a collaborator - Load existing document
              loadDocumentData(querySnapshot);
            } else {
              // User is a new collaborator - Add them to collaborators
              await updateDoc(documentRef, {
                mode: "collaboration",
                collaborators: [
                  ...querySnapshot.data().collaborators,
                  userData,
                ],
                typingUser: null,
                collaboratorIds: [
                  ...querySnapshot.data().collaboratorIds,
                  userID,
                ],
              });

              // Load the document data for the new collaborator
              loadDocumentData(querySnapshot);
            }
          } else {
            // User is not the creator - Recreate document under their ownership
            if (
              querySnapshot.data()?.creator === userID &&
              action !== "share"
            ) {
              return;
            }
            if (action === "share") {
              await setDoc(documentRef, {
                _id: newDocumentId,
                creator: userID,
                data: querySnapshot.data().data,
                type: type,
                mode: mode,
                title: propTittle,
                collaborators: querySnapshot.data().collaborators || [],
                collaboratorIds: [],
                typingUser: null,
                date: moment().format("MMMM D, YYYY"),
                timestamp: new Date(),
              });
              loadDocumentData(querySnapshot);
            } else {
              console.log("This is not supported");
              return;
            }
          }
        } else {
          // Document does not exist - Create a new document
          if (querySnapshot.data()?._id === documentId) {
            return;
          }
          await setDoc(documentRef, {
            _id: documentId,
            creator: userID,
            data: JSON.stringify({ ops: [] }),
            type: type,
            mode: mode,
            title: propTittle,
            collaborators: [],
            typingUser: null,
            date: moment().format("MMMM D, YYYY"),
            timestamp: new Date(),
          });

          quill.setContents({ ops: [] });
          quill.enable();
          setTitle(propTittle);
          setIdChecked(true);
          setIsLoading(false);
          setLastSavedContent(quill.getContents());
        }
      } catch (error) {
        console.error("Error finding or creating document:", error);
        setIsLoading(false);
      }
    };

    findOrCreate();
  }, [quill, documentId, propTittle]);

  // Initialize Quill editor
  useEffect(() => {
    const wrapperRef = (wrapper) => {
      if (wrapper == null && !idChecked) return;
      wrapper.innerHTML = "";
      const editor = document.createElement("div");
      wrapper.append(editor);
      const q = new Quill(editor, options);
      q.getModule("toolbar").addHandler("image", () => {
        selectLocalImage(q);
      });
      q.disable();
      q.setText("Loading...");
      setIsLoading(true);
      setQuill(q);
      setIsLoading(false);
    };

    const wrapper = document.querySelector(".editor");
    wrapperRef(wrapper);
  }, [idChecked]);

  // Update Firestore with title changes
  useEffect(() => {
    if (idChecked && title !== "") {
      updateDoc(documentRef, {
        title: title,
        updatedAt: serverTimestamp(),
      });
    }
  }, [title, idChecked]);

  // Handle Quill content changes and save to Firestore (Real-time for collaboration)
  const handleContentChange = useCallback(
    async (delta, oldDelta, source) => {
      if (source !== "user" && !idChecked) return;
      if (mode === "collaboration") {
        // Real-time update for collaboration mode
        const content = quill.getContents();
        await updateDoc(documentRef, {
          data: JSON.stringify(content),
          title: title,
          updatedAt: serverTimestamp(),
        });
      } else {
        // Debounced update for non-collaboration mode
        const content = quill.getContents();
        if (content !== lastSavedContent) {
          await updateDoc(documentRef, {
            data: JSON.stringify(content),
            title: title,
            updatedAt: serverTimestamp(),
          });
          setLastSavedContent(content);
        }
      }
    },
    [mode, quill, title, idChecked, lastSavedContent]
  );

  // Subscribe to changes in the document
  useEffect(() => {
    if (quill == null) return;

    const unsubscribe = onSnapshot(documentRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data().data;
        let delta = new Delta();
        if (data) {
          try {
            delta = new Delta(JSON.parse(data));
          } catch (jsonError) {
            toast.error("Error parsing JSON data:" || jsonError);
          }
        }

        // Store the current cursor position
        const currentSelection = quill.getSelection();

        // Update the Quill editor with the latest changes
        quill.setContents(delta, "silent");

        // Restore the cursor position
        if (currentSelection) {
          quill.setSelection(currentSelection);
        }
        const currentUser = doc
          .data()
          .collaborators?.find((collaborator) => collaborator.id === userID);
        setCurrentUserEditing(currentUser?.name || null);

        // Update collaborators state
        const fetchedCollaborators = doc.data().collaborators || [];
        const collaboratorsWithRoles = fetchedCollaborators.map(
          (collaborator) => {
            // Add the role property based on the creator
            const role =
              collaborator.id === doc.data().creator ? "Owner" : "User";
            return { ...collaborator, role };
          }
        );

        setCollaboratorsState(collaboratorsWithRoles);
      }
    });

    return () => unsubscribe();
  }, [quill]);

  // Attach Quill event listeners
  useEffect(() => {
    if (quill == null) return;
    quill.on("text-change", handleContentChange);
    return () => {
      quill.off("text-change", handleContentChange);
    };
  }, [quill, title, idChecked, handleContentChange]);

  // Initialize Quill editor with Markdown module
  const wrapperRef = useCallback((wrapper) => {
    if (wrapper == null && !idChecked) return;
    wrapper.innerHTML = "";
    const editor = document.createElement("div");
    wrapper.append(editor);
    const q = new Quill(editor, options);

    // Register the Markdown module BEFORE creating the Quill instance
    Quill.register("modules/markdown", QuillMarkdown, true);

    // Add the image handler after Quill is initialized
    q.getModule("toolbar").addHandler("image", () => {
      selectLocalImage(q);
    });

    q.disable();
    q.setText("Loading...");
    setIsLoading(true);
    setQuill(q);
    setIsLoading(false);
  }, []);

  // Handle selection changes and display AI button
  useEffect(() => {
    if (quill == null && !idChecked) return;
    const handleSelectionChange = (range) => {
      if (document.activeElement.tagName === "INPUT") return;
      if (range == null || range.length === 0) {
        setAskAIVisible(false);
        setPopupVisible(false);
        return;
      }
      const bounds = quill.getBounds(range);
      const containerBounds = quill.container.getBoundingClientRect();
      setPopupPosition({
        top: containerBounds.top + window.scrollY + bounds.top,
        bottom: containerBounds.top + window.scrollY + bounds.bottom,
        left:
          containerBounds.left +
          window.scrollX +
          bounds.left +
          bounds.width / 2,
      });

      setAskAIVisible(true);
    };
    quill.on("selection-change", handleSelectionChange);
    return () => {
      quill.off("selection-change", handleSelectionChange);
    };
  }, [quill, idChecked]);

  /**
   * Selects an image from the user's local machine.
   *
   * @param {Quill} quill The Quill editor instance.
   */
  const selectLocalImage = (quill) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        const imageUrl = await uploadImageToGCS(file);
        const range = quill.getSelection();
        quill.insertEmbed(range.index, "image", imageUrl);

        // Update Firestore with the new image URL
        const content = quill.getContents();
        await updateDoc(documentRef, {
          data: JSON.stringify(content),
          title: title,
          updatedAt: serverTimestamp(),
        });
      } else {
        console.warn("You can only upload images.");
      }
    };
  };

  /**
   * Asks the AI for assistance with the selected text.
   *
   * @param {string} action The desired AI action (e.g., "improve writing", "fix grammar").
   * @param {string} prompt Optional prompt for the AI.
   */
  const handleAskAI = async (action, prompt = "") => {
    const selection = quill.getSelection();
    if (selection) {
      const selectedText = quill.getText(selection.index, selection.length);

      try {
        setLoading(true);

        const postData = {
          text: selectedText,
          action,
          prompt,
        };

        const response = await api.post(
          constants.AI_GENERATE_ENDPOINT,
          postData
        );
        const data = response.data;

        setLoading(false);
        setGeminiResponse(data);
        setPopupVisible(true);
        setAskAIVisible(false);
      } catch (error) {
        console.error("Error asking AI:", error);
        setLoading(false);
      }
    }
  };

  // Handle AI response actions
  const handleReplaceSelection = () => {
    const selection = quill.getSelection();
    if (selection && geminiResponse) {
      const html = marked(geminiResponse.text);
      quill.deleteText(selection.index, selection.length);
      quill.clipboard.dangerouslyPasteHTML(selection.index, html);
      setPopupVisible(false);
      setGeminiResponse(null);
      setAskAIVisible(false);
    }
  };

  const handleInsertBelow = () => {
    const selection = quill.getSelection();
    if (selection && geminiResponse) {
      const html = marked(geminiResponse.text);
      quill.clipboard.dangerouslyPasteHTML(
        selection.index + selection.length,
        html
      );
      setPopupVisible(false);
      setGeminiResponse(null);
      setAskAIVisible(false);
    }
  };

  const handleDiscard = () => {
    setGeminiResponse(null);
    setPopupVisible(false);
    setAskAIVisible(false);
  };

  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo({ top: 1, behavior: "smooth" });
  }, []);

  // Render the AI response
  const renderAIResponse = () => {
    if (geminiResponse.text) {
      const renderedHtml = marked(geminiResponse.text);
      return (
        <div
          className="ask-ai-popup-response"
          dangerouslySetInnerHTML={{ __html: renderedHtml }}
        ></div>
      );
    }
  };

  // Render the AI options popup
  const renderAIOptions = () => (
    <div
      className="ask-ai-popup"
      style={{
        top: `${popupPosition.bottom + 10}px`,
        left: `${popupPosition.left}px`,
        transform: "translateX(-50%)",
        marginTop: "1rem",
      }}
      onMouseDown={(e) => e.stopPropagation()}
    >
      {!geminiResponse ? (
        <>
          {loading ? (
            <div>
              <Loading
                normalLoading={false}
                tittle={"AI is thinking. Please wait..."}
              />
            </div>
          ) : (
            <>
              <div className="ask-ai-popup-input">
                <AutoAwesomeIcon
                  style={{ color: "#6b21a8", marginRight: "10px" }}
                />
                <input
                  style={{ flex: 1, color: "#4a4a4a" }}
                  type="text"
                  placeholder="Ask AI to edit or generate..."
                  onFocus={() => {
                    savedSelection.current = quill.getSelection();
                  }}
                  disabled
                  onBlur={() => {
                    if (savedSelection.current) {
                      quill.setSelection(savedSelection.current);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleAskAI("customize", e.target.value);
                    }
                  }}
                />
                <ArrowCircleUpIcon
                  onClick={(e) => handleAskAI("customize", e.target.value)}
                  style={{ color: "#a855f7" }}
                />
              </div>
              <div className="ask-ai-popup-top">
                <div className="ask-ai-popup-topTittle">
                  <Typography variant="subtitle2" color="gray">
                    Edit or review selection
                  </Typography>
                </div>
                <div className="ask-ai-popup-topButtons">
                  <button onClick={() => handleAskAI("improve writing")}>
                    <span>
                      <EditIcon style={{ color: "#A855F7" }} />
                    </span>
                    Improve writing
                  </button>
                  <button onClick={() => handleAskAI("fix grammar")}>
                    <span>
                      <SpellcheckIcon style={{ color: "#A855F7" }} />
                    </span>
                    Fix grammar
                  </button>
                  <button onClick={() => handleAskAI("make shorter")}>
                    <span>
                      <ShortTextIcon style={{ color: "#A855F7" }} />
                    </span>
                    Make shorter
                  </button>
                  <button onClick={() => handleAskAI("make longer")}>
                    <span>
                      <FormatAlignJustifyIcon style={{ color: "#A855F7" }} />
                    </span>
                    Make longer
                  </button>
                </div>
              </div>
              <div className="ask-ai-popup-bottom">
                <div className="ask-ai-popup-topTittle">
                  <Typography variant="subtitle2" color="gray">
                    Use AI to do more
                  </Typography>
                </div>
                <div className="ask-ai-popup-topButtons">
                  <button onClick={() => handleAskAI("continue writing")}>
                    <span>
                      <ArrowForwardIcon style={{ color: "#A855F7" }} />
                    </span>
                    Continue writing
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className="ask-ai-popup-response">
            <div className="ask-ai-popup-topTittle">
              <Typography variant="subtitle2" color="gray">
                AI Response
              </Typography>
            </div>
            <p>{renderAIResponse()}</p>
          </div>
          <div className="ask-ai-popup-top">
            <div className="ask-ai-popup-topButtons">
              <button onClick={handleReplaceSelection}>
                <span>
                  <EditIcon style={{ color: "#A855F7" }} />
                </span>
                Replace Selection
              </button>
              <button onClick={handleInsertBelow}>
                <span>
                  <SpellcheckIcon style={{ color: "#A855F7" }} />
                </span>
                Insert Below
              </button>
            </div>
          </div>
          <div className="ask-ai-popup-topButtons">
            <button onClick={handleDiscard}>
              <span>
                <ArrowForwardIcon style={{ color: "#A855F7" }} />
              </span>
              Discard
            </button>
          </div>
        </>
      )}
    </div>
  );

  // Handle title input changes
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  // Handle export to Word document
  const handleExportWord = () => {
    const content = quill.root.innerHTML;
    const blob = htmlDocx.asBlob(content);
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${title}.docx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Handle enabling collaboration mode
  const handleInitiateCollaboration = async () => {
    try {
      if (mode === "collaboration") {
        // Collaboration mode is already enabled
        return;
      }

      // Update the document to enable collaboration
      await updateDoc(documentRef, {
        mode: "collaboration",
        collaborators: [userData],
        collaboratorIds: [userID],
      });

      toast.success("Collaboration mode enabled!");
    } catch (error) {
      console.error("Error initiating collaboration:", error);
      toast.error("Failed to enable collaboration mode. Please try again.");
    }
  };

  // Disable/enable Quill editor based on user editing status
  useEffect(() => {
    if (quill && currentUserEditing === userID) {
      quill.enable();
    } else if (quill) {
      quill.disable();
    }
  }, [quill, currentUserEditing]);

  // Track typing user
  useEffect(() => {
    if (quill) {
      quill.on("text-change", (delta, oldDelta, source) => {
        if (source === "user") {
          // Update Firestore with typing user
          updateDoc(documentRef, {
            typingUser: currentUserEditing,
          });
        } else {
          // Clear typing user in Firestore
          updateDoc(documentRef, {
            typingUser: null,
          });
        }
      });
    }

    // Clean up the event listener
    return () => {
      if (quill) {
        quill.off("text-change");
      }
    };
  }, [quill, currentUserEditing]);

  // Update typingUser state based on Firestore
  useEffect(() => {
    const unsubscribe = onSnapshot(documentRef, (doc) => {
      if (doc.exists()) {
        setTypingUser(doc.data().typingUser);
      }
    });
    return () => unsubscribe();
  }, [documentRef]);

  const collaborators =
    collaboratorsState.length > 0 ? collaboratorsState : [userData];
  return (
    <HelmetProvider>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "90vh",
          }}
        >
          <Loading
            normalLoading={true}
            tittle={"loading, please wait......."}
          />
        </Box>
      ) : (
        <div className="container">
          <div className="document_header">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={1}>
                <Link to="/">
                  <ArrowBackIcon sx={{ fontSize: "2rem", color: "#5551ff" }} />
                </Link>
              </Grid>
              <Grid item xs={12} md={5} lg={4}>
                <div className="document_headerInput">
                  <Typography variant="h6" color="black">
                    Tittle:
                  </Typography>
                  <input
                    type="text"
                    value={title}
                    onChange={handleTitleChange}
                    placeholder="Enter document title"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={7}>
                <div className="document_buttons">
                  <InsertToDocument quill={quill} documentId={documentId} />
                  <DocumentCharBox quill={quill} documentId={documentId} />
                  <ChartBox quill={quill} />
                  <button
                    className="recorder_button"
                    onClick={handleExportWord}
                  >
                    <FileCopyIcon />
                    <span>Download</span>
                  </button>
                  <>
                    <CollaboratorsAvatars
                      collaborators={collaborators}
                      handleInitiateCollaboration={handleInitiateCollaboration}
                    />{" "}
                  </>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="editor" ref={wrapperRef}></div>
          {askAIVisible && !popupVisible && (
            <button
              className="ask-ai-button"
              style={{
                position: "absolute",
                top: `${popupPosition.bottom}px`,
                left: "10%",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setPopupVisible(true);
                setGeminiResponse(null);
                setAskAIVisible(false);
              }}
            >
              Ask AI
            </button>
          )}
          {popupVisible && renderAIOptions()}
          {currentUserEditing && (
            <div className="current-user-editing">
              <Typography variant="caption" color="gray">
                {currentUserEditing} is editing
              </Typography>
            </div>
          )}
          {/* Show typing indicator */}
          {typingUser && (
            <div className="typing-indicator">
              <Typography variant="caption" color="gray">
                {typingUser} is typing...
              </Typography>
            </div>
          )}
        </div>
      )}
    </HelmetProvider>
  );
}
