import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "../../config/firebase";
import Image from "../../assets/image.png";
import Logo from "../../assets/logo.png";
import GoogleSvg from "../../assets/icons8-google.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import "./Login.css";
import * as constants from "../../utils/constants/index";
import api from "../../utils/fetch-data/api";
import { Alert } from "@mui/material";
import { setCookieToken, storeToken } from "../../utils/ProtectedRoute";
import CircularProgress from "@mui/material/CircularProgress";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const location = useLocation();
  const from = location.state?.prevUrl || "/";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!formValues.email || !formValues.password) {
      toast.error("Please fill in all the inputs");
      return;
    }
    setLoading(true);
    try {
      const response = await api.post(constants.LOGIN_ENDPOINT, formValues);
      if (response.status === 200) {
        storeToken(response.data);
        setCookieToken(response.data.jwtToken);
      }
      toast.success("You have successfully logged in.", {
        className: "toast-message",
      });
      navigate(from);
      setFormValues({
        email: "",
        password: "",
      });
    } catch (error) {
      setError(error || "There was an error login in please try again");
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const idToken = await user.getIdToken();

      // Send the ID token to your server
      const response = await api.post(constants.GOOGLE_LOGIN_ENDPOINT, {
        idToken,
      });

      // Handle the response from your server
      if (response.status === 200) {
        storeToken(response.data);
        setCookieToken(response.data.jwtToken);
        toast.success("You have successfully logged in.", {
          className: "toast-message",
        });
        navigate(from);
      } else {
        throw new Error("Google login failed");
      }
    } catch (error) {
      console.log(error);
      setError(error || " Google Sign-In failed:");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-main">
      <div className="login-left">
        <img src={Image} alt="" />
      </div>
      <div className="login-right">
        <div className="login-right-container">
          <div className="login-logo">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="login-center">
            <h2>Welcome back!</h2>
            <p>Please enter your details</p>
            {error && (
              <Alert
                sx={{ marginBottom: 5, textAlign: "center" }}
                severity="error"
              >
                {error.error}
              </Alert>
            )}
            <form onSubmit={handleLogin}>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formValues.email}
                onChange={handleInputChange}
                required
              />
              <div className="pass-input-div">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  value={formValues.password}
                  onChange={handleInputChange}
                  required
                />
                {showPassword ? (
                  <VisibilityOff
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <Visibility
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
              <div className="login-center-buttons">
                <button type="submit" disabled={loading}>
                  {loading ? <CircularProgress size={24} /> : "Sign in"}
                </button>
                <button
                  type="button"
                  onClick={handleGoogleLogin}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <>
                      <img src={GoogleSvg} alt="Google" />
                      Sign In with Google
                    </>
                  )}
                </button>
              </div>
            </form>

            <div className="sign-up">
              <p>
                Don&apos;t have an account?{" "}
                <Link
                  to="/sign-up"
                  state={{ prevUrl: from }}
                  className="sign-up-link"
                >
                  Sign up
                </Link>
              </p>
              <p>
                <Link
                  to="/forgot-password"
                  state={{ prevUrl: from }}
                  className="sign-up-link"
                >
                  Forgot password
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
