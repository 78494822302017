import React, { useEffect, useState } from "react";
import { Avatar, Menu, MenuItem } from "@mui/material";
import logo from "../../assets/li.png";
import "./index.css";
import * as constants from "../../utils/constants/index";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../utils/fetch-data/api";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [profileName, setProfileName] = useState("");
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      const response = await api.post(constants.LOGOUT_ENDPOINT);

      if (response.status === 200) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("tokenExpirationTime");
        localStorage.removeItem("userData");
        navigate("/login");
        window.location.reload();
      } else {
        toast.error("Failed to logout");
      }
    } catch (error) {
      toast.error("Error during logout: " + error.message);
    }
  };

  const handleHomeNavigate = () => {
    navigate("/");
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const uuid = accessToken ? accessToken : handleLogout();
        const res = await api.get(`${constants.GET_USER_ENDPOINT}/${uuid}`);

        const displayName = res.data.displayName || "";
        const nameParts = displayName.split(" ");
        const profileName = `${nameParts[0][0]}`;
        const user = {
          gmail: res.data.email,
          name: res.data.displayName,
          profile: profileName,
          id: uuid,
        };
        localStorage.setItem("userData", JSON.stringify(user));
        setProfileName(profileName);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      const userData = JSON.parse(storedUserData);
      setProfileName(userData.profile);
    } else {
      fetchUser();
    }
  }, []);

  return (
    <div className="header">
      <div className="top_header">
        <div onClick={() => handleHomeNavigate()} className="logo_img">
          <img src={logo} alt="logo" />
        </div>
        <div>
          <Avatar
            onClick={handleMenuOpen}
            sx={{ background: "#0c8ce9", padding: 3, cursor: "pointer" }}
          >
            {profileName.toLocaleUpperCase()}
          </Avatar>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              sx={{
                minWidth: "100px",
                minHeight: "50px",
                marginTop: 1,
              }}
              onClick={() => handleLogout()}
            >
              Logout
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Header;
