import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets (Ensure these images are high-resolution)
import AddImage1 from "../../assets/img/add/1.png";
import AddImage2 from "../../assets/img/add/6.png";
import AddImage3 from "../../assets/img/add/3.png";
import AddImage4 from "../../assets/img/add/4.png";
import AddImage5 from "../../assets/img/add/7.png";
import AddImage6 from "../../assets/img/add/8.png";
import AddImage7 from "../../assets/img/add/9.png";
import AddImage8 from "../../assets/img/add/11.png";
import Projects from "./Projects";
import { useNavigate } from "react-router-dom";

export default function Services() {
  const navigate = useNavigate();
  const handleLoginNavigate = () => {
    navigate("login");
  };
  const handleRegisterNavigate = () => {
    navigate("login");
  };
  return (
    <Wrapper id="services">
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Services</h1>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="roller"
                title="For Students"
                subtitle="Lecture Summaries: Upload or record lectures to receive real-time, concise summaries, helping you grasp essential points quickly and effectively."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="monitor"
                title="For Researchers"
                subtitle="Research Paper Summaries: Upload research papers and articles to generate concise summaries, making it easier to review and reference key findings."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="For Academics"
                subtitle="Note Enhancement: Enhance and organize your academic notes with AI suggestions, ensuring clarity and thoroughness in your study materials."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="printer"
                title="For Educational Institutions"
                subtitle="Course Content Summaries: Summarize course materials and readings to provide students with easily digestible information, aiding in their overall understanding and retention."
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h2 className="font40 extraBold">
                  Content Analysis and Summarization
                </h2>
                <p className="font16">
                  <span className="extraBold"> Multi-Source Input:</span>
                  At typeABC, you can seamlessly upload a variety of content
                  including audio files, PDFs, YouTube links, or record audio
                  directly on our platform. Our sophisticated AI algorithms
                  meticulously analyze these inputs to generate concise,
                  coherent, and insightful summaries. Whether it's a lecture, an
                  article, or a video, we ensure that you get the essence of the
                  content in a digestible format, saving you time and effort.
                </p>
                <p className="font16">
                  <span className="extraBold">Real-Time Summaries:</span>
                  Stay on top of your content with our real-time summary
                  feature. Powered by Socket.IO, this service offers instant
                  summaries as you record or upload lectures, ensuring you have
                  immediate access to the information you need. This feature is
                  particularly useful for students and professionals who require
                  quick and accurate summaries during live events or meetings.
                </p>
                <ButtonsRow
                  className="flexNullCenter"
                  style={{ margin: "30px 0" }}
                >
                  <div style={{ width: "190px" }}>
                    <FullButton
                      title="Get Started"
                      action={handleRegisterNavigate}
                    />
                  </div>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <ImagesRow className="flex">
                    <AddImgWrapp>
                      <img src={AddImage1} alt="office" />
                    </AddImgWrapp>
                    <AddImgWrapp>
                      <img src={AddImage2} alt="office" />
                    </AddImgWrapp>
                  </ImagesRow>
                  <ImagesRow className="flex">
                    <AddImgWrapp>
                      <img src={AddImage3} alt="office" />
                    </AddImgWrapp>
                    <AddImgWrapp>
                      <img src={AddImage4} alt="office" />
                    </AddImgWrapp>
                  </ImagesRow>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
        <Projects />
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h2 className="font40 extraBold">AI-Powered Text Editing</h2>
                <p className="font16">
                  <span className="extraBold">Advanced Text Editor:</span>
                  Our state-of-the-art text editor is designed to provide
                  flexibility and power at your fingertips. You can easily
                  upload documents, insert links, and engage with AI to edit and
                  enhance your content. The editor offers smart recommendations
                  based on the context, helping you improve your writing
                  effortlessly. Additionally, the built-in chat feature allows
                  you to interact with the AI for document-specific queries,
                  making the editing process more interactive and efficient.
                </p>
                <p className="font16">
                  <span className="extraBold">Writing Assistant:</span>
                  Enhance your writing with our intelligent writing assistant.
                  This tool provides targeted suggestions to refine your
                  content, whether you need to shorten lengthy passages, expand
                  concise sections, or clarify complex ideas. By leveraging
                  advanced natural language processing, the writing assistant
                  ensures your text is clear, concise, and impactful, catering
                  to both casual and professional writing needs.
                </p>
                <ButtonsRow
                  className="flexNullCenter"
                  style={{ margin: "30px 0" }}
                >
                  <div style={{ width: "190px" }}>
                    <FullButton
                      title="Get Started"
                      action={handleRegisterNavigate}
                    />
                  </div>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <ImagesRow className="flex">
                    <AddImgWrapp>
                      <img src={AddImage5} alt="office" />
                    </AddImgWrapp>
                    <AddImgWrapp>
                      <img src={AddImage6} alt="office" />
                    </AddImgWrapp>
                  </ImagesRow>
                  <ImagesRow className="flex">
                    <AddImgWrapp>
                      <img src={AddImage7} alt="office" />
                    </AddImgWrapp>
                    <AddImgWrapp>
                      <img src={AddImage8} alt="office" />
                    </AddImgWrapp>
                  </ImagesRow>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin-top: 30px;
  @media (max-width: 1160px) {
    flex-direction: column;
    padding: 0 0 50px 0;
    margin: 40px 0;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 1160px) {
    width: 100%;
    order: 2;
    text-align: center;
    p {
      margin: 0 auto;
    }
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 1160px) {
    justify-content: center;
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 1160px) {
    width: 100%;
    order: 1;
    margin-bottom: 40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
  margin-left: 5%;
  @media (max-width: 400px) {
    margin-left: 0;
  }
`;
const ImagesRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const AddImgWrapp = styled.div`
  width: 48%;
  img {
    width: 100%;
    height: auto;
    max-width: 520px;
    max-height: 400px;
  }
`;
