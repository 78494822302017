import React from "react";
import "./Loading.css";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

const Loading = ({ normalLoading, textLoading, tittle }) => {
  return (
    <div className="loading">
      <div className="title">{tittle}</div>
      {normalLoading ? (
        <div className="loading-container">
          <div className="loading-text">{textLoading}</div>
          <div className="loading-wave">
            <div className="obj"></div>
            <div className="obj"></div>
            <div className="obj"></div>
            <div className="obj"></div>
            <div className="obj"></div>
            <div className="obj"></div>
            <div className="obj"></div>
            <div className="obj"></div>
          </div>
        </div>
      ) : (
        <div className="loading-ai">
          <span className="ai-text">{textLoading}</span>
          <AutoAwesomeIcon fontSize="10rem" className="rotating-icon" />
        </div>
      )}
    </div>
  );
};

export default Loading;
