import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import AddIcon from "@mui/icons-material/Add";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import LinkIcon from "@mui/icons-material/Link";
import { getRandomColor } from "../../pages/Home/Home";
import CopyToClipboard from "react-copy-to-clipboard";
import { useParams } from "react-router-dom";

const CollaboratorsAvatars = ({
  collaborators,
  handleInitiateCollaboration,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [copied, setCopied] = useState(false);
  const { id: documentID, title, type, mode, action } = useParams();

  const handleAddClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const newUrl = `${window.location.origin}#/document/${documentID}/${title}/${type}/collaboration`;

  const handleCopyLink = () => {
    handleInitiateCollaboration();
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);

    console.log("Link copied", newUrl);
  };

  const displayCollaborators = collaborators.slice(0, 2);
  const remainingCollaborators =
    collaborators.length - displayCollaborators.length;

  return (
    <div>
      <AvatarGroup max={4}>
        {displayCollaborators.map((collaborator, index) => (
          <Avatar
            key={index}
            sx={{
              background: getRandomColor(),
              cursor: "pointer",
            }}
          >
            {collaborator.profile.toUpperCase()}
          </Avatar>
        ))}
        {remainingCollaborators > 0 && (
          <Avatar
            sx={{
              background: getRandomColor(),
              cursor: "pointer",
            }}
          >
            +{remainingCollaborators}
          </Avatar>
        )}
        <Avatar
          sx={{
            background: "#5551ff",
            cursor: "pointer",
          }}
          onClick={handleAddClick}
        >
          <AddIcon sx={{ fontSize: "2rem" }} />
        </Avatar>
      </AvatarGroup>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2, width: 450 }}>
          <TextField
            label="Add names, groups or emails"
            variant="outlined"
            fullWidth
            disabled
          />
          <List>
            {collaborators.map((collaborator, index) => (
              <ListItem key={index}>
                <Avatar sx={{ background: "#0c8ce9", marginRight: 2 }}>
                  {collaborator.profile.toUpperCase()}
                </Avatar>
                {collaborator.status ? (
                  <ListItemText
                    primary={collaborator.gmail}
                    secondary={collaborator.status}
                  />
                ) : (
                  <ListItemText
                    primary={collaborator.name}
                    secondary={collaborator.gmail}
                  />
                )}
                <Typography variant="body2" color="textSecondary">
                  {collaborator.role}
                </Typography>
                <IconButton edge="end" size="small">
                  <MoreVertIcon />
                </IconButton>
              </ListItem>
            ))}
            <ListItem>
              <CopyToClipboard text={newUrl} onCopy={handleCopyLink}>
                <Button
                  startIcon={<LinkIcon />}
                  fullWidth
                  sx={{
                    bgcolor: "#7620ff",
                    color: "white",
                    "&:hover": {
                      bgcolor: "#7620ff",
                    },
                  }}
                >
                  {copied ? "Link copied" : "Copy Link"}
                </Button>
              </CopyToClipboard>
            </ListItem>
          </List>
        </Typography>
      </Popover>
    </div>
  );
};

export default CollaboratorsAvatars;
