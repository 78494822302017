import { toast } from "react-toastify";
import api from "./api";

// Function to handle GET requests
export const get = async (endpoint, params = {}) => {
  try {
    const response = await api.get(endpoint, { params });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Function to handle POST requests
export const post = async (endpoint, data) => {
  try {
    const response = await api.post(endpoint, data);
    console.log(response);
    return response;
  } catch (error) {
    handleError(error);
  }
};

// Function to handle PUT requests
export const put = async (endpoint, data) => {
  try {
    const response = await api.put(endpoint, data);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Function to handle DELETE requests
export const del = async (endpoint) => {
  try {
    const response = await api.delete(endpoint);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Error handling function
const handleError = (error) => {
  // Customize error handling as needed
  toast.error(error.message);
};
