import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage2 from "../../assets/img/add/5.png";
import { useNavigate } from "react-router-dom";

export default function Projects() {
  const navigate = useNavigate();
  const handleLoginNavigate = () => {
    navigate("login");
  };
  const handleRegisterNavigate = () => {
    navigate("login");
  };
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h2 className="font40 extraBold"> Interactive AI Tools</h2>
              <p className="font16">
                <span className="extraBold">Chatbots:</span>
                Experience the convenience of having two specialized chatbots at
                your disposal. One chatbot is dedicated to document
                interactions, helping you with tasks such as rewriting,
                summarizing, and enhancing your text. The second chatbot
                addresses broader queries, providing a comprehensive assistance
                experience. These chatbots are designed to make your interaction
                with typeABC intuitive and productive, offering real-time
                support and feedback.
              </p>
              <p className="font16">
                <span className="extraBold">
                  Customization and Personalization:
                </span>
                We understand that each user has unique needs. That's why our
                platform offers extensive customization and personalization
                options. Tailor your summaries and documents to match your
                specific requirements, ensuring that the output is not only
                useful but also highly relevant. Whether it's adjusting the
                format, style, or focus of the summaries, our tools empower you
                to create content that truly meets your needs.
              </p>
              <ButtonsRow
                className="flexNullCenter"
                style={{ margin: "30px 0" }}
              >
                <div style={{ width: "190px" }}>
                  <FullButton
                    title="Get Started"
                    action={handleRegisterNavigate}
                  />
                </div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
