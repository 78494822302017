import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import Landing from "../pages/landing-page/Landing";

// Function to set the cookie token
export const setCookieToken = (loginToken) => {
  // Get the existing cookie
  const token = Cookies.get("token");

  if (!token) {
    // Set the cookie with the proper domain attribute
    Cookies.set("token", loginToken, {
      expires: 30,
      path: "/",
    });
    console.log("Token set");
  }
};

// Function to store the token in local storage
export const storeToken = (token) => {
  const expirationTime = new Date().getTime() + 30 * 24 * 60 * 60 * 1000;
  localStorage.setItem("accessToken", token);
  localStorage.setItem("tokenExpirationTime", expirationTime);
};

// Function to clear token from local storage
const clearToken = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("tokenExpirationTime");
};

// Function to get token from local storage
const getToken = () => {
  const token = localStorage.getItem("accessToken");
  const expirationTime = localStorage.getItem("tokenExpirationTime");

  if (token && expirationTime) {
    const currentTime = new Date().getTime();
    if (currentTime > expirationTime) {
      clearToken();
      return null;
    }
    return token;
  }
  return null;
};

// ProtectedRoute component
export const ProtectedRoute = ({ children, fallback = null }) => {
  const accessToken =
    Cookies.get("token") || Cookies.get("frontendToken") || getToken();

  const token = accessToken ? true : null;

  if (!token) {
    return fallback || <Landing />;
  }

  return children;
};

// RedirectLoggedIn component
export const RedirectLoggedIn = ({ children }) => {
  const accessToken =
    Cookies.get("token") || Cookies.get("frontendToken") || getToken();

  const token = accessToken ? true : null;
  const location = useLocation();
  const from = location.state?.prevUrl || "/";

  if (token) {
    return <Navigate to={from} />;
  }

  return React.cloneElement(children, { state: { prevUrl: from } });
};
