import React, { useEffect, useState } from "react";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Description } from "@mui/icons-material";
import AudioRecorder from "../../components/audio-recorder/AudioRecorder";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import "./Home.css";
import Loading from "../../components/loading/Loading";
import {
  getDocs,
  collection,
  query,
  where,
  deleteDoc,
  doc,
  orderBy,
} from "firebase/firestore";
import { db } from "../../config/firebase";
import { Helmet, HelmetProvider } from "react-helmet-async";

// Define background colors for document cards
const cardBackgroundColors = [
  { bg: "#FEC971", color: "#0000" },
  { bg: "#FE9B72", color: "#0000" },
  { bg: "#E3EF8F", color: "#0000" },
  { bg: "#B693FD", color: "#0000" },
  { bg: "#00D4FE", color: "#0000" },
  { bg: "#EAEAE6", color: "#0000" },
  { bg: "#C7CBEE", color: "#0000" },
  { bg: "#C7E3C8", color: "#0000" },
  { bg: "#C7E3C8", color: "#0000" },
  { bg: "#FBBE28", color: "#0000" },
];

/**
 * Gets a random background color from the defined list.
 *
 * @returns {string} A random background color.
 */
export const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * cardBackgroundColors.length);
  return cardBackgroundColors[randomIndex].bg;
};

/**
 * The Home component displays the user's notes and provides actions.
 *
 * @returns {JSX.Element} The Home component.
 */
const Home = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Lecture"); // Track active tab
  const [lectureDocuments, setLectureDocuments] = useState([]); // Store fetched lecture documents
  const [personalDocuments, setPersonalDocuments] = useState([]); // Store fetched personal documents
  const [collaboratedDocuments, setCollaboratedDocuments] = useState([]); // Store fetched collaborated documents
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [isDeleting, setIsDeleting] = useState(false); // Deletion state
  const [isSharing, setIsSharing] = useState(false); // Sharing state
  const [documentToDelete, setDocumentToDelete] = useState(null); // Document to delete
  const [documentToShare, setDocumentToShare] = useState(null); // Document to share
  const [isCopied, setIsCopied] = useState(false); // Clipboard copy state
  const currentUserId = localStorage.getItem("accessToken"); // Current user's ID

  /**
   * Fetches lecture documents from Firestore.
   */
  const fetchLectureDocuments = async () => {
    setIsLoading(true);
    try {
      const q = query(
        collection(db, "document"),
        where("type", "==", "Lecture"),
        where("creator", "==", currentUserId), // Only fetch lecture documents created by the user
        orderBy("timestamp", "desc")
      );

      const querySnapshot = await getDocs(q);
      const docs = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLectureDocuments(docs);
    } catch (err) {
      toast.error("Error fetching lecture documents: " || err);
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Fetches personal documents from Firestore.
   */
  const fetchPersonalDocuments = async () => {
    setIsLoading(true);
    try {
      const q = query(
        collection(db, "document"),
        where("type", "==", "Personal"),
        where("creator", "==", currentUserId), // Only fetch personal documents created by the user
        orderBy("timestamp", "desc")
      );

      const querySnapshot = await getDocs(q);
      const docs = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPersonalDocuments(docs);
    } catch (err) {
      toast.error("Error fetching personal documents: " || err);
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Fetches collaborated documents from Firestore.
   */
  const fetchCollaboratedDocuments = async () => {
    setIsLoading(true);
    try {
      const q = query(
        collection(db, "document"),
        where("collaboratorIds", "array-contains", currentUserId)
      );

      const querySnapshot = await getDocs(q);
      const docs = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCollaboratedDocuments(docs);
    } catch (err) {
      toast.error("Error fetching collaborated documents: " || err);
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch documents on component mount
  useEffect(() => {
    fetchLectureDocuments();
    fetchPersonalDocuments();
    fetchCollaboratedDocuments();
  }, []);

  // Group documents by month
  const lectureDocumentsByMonth = groupByMonth(lectureDocuments);
  const personalDocumentsByMonth = groupByMonth(personalDocuments);
  const collaboratedDocumentsByMonth = groupByMonth(collaboratedDocuments);

  /**
   * Handles navigation to a document's single page.
   *
   * @param {string} documentId The ID of the document.
   * @param {string} documentTitle The title of the document.
   * @param {string} documentType The type of the document.
   * @param {string} documentMode The mode of the document.
   */
  const handleViewDocument = (
    documentId,
    documentTitle,
    documentType,
    documentMode
  ) => {
    if (!documentId || !documentTitle || !documentType || !documentMode) {
      toast.error("Can't view an item at the moment");
      return;
    }
    const encodedTitle = encodeURIComponent(documentTitle);
    navigate(
      `/document/${documentId}/${encodedTitle}/${documentType}/${documentMode}`
    );
  };

  // Handle tab change
  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  /**
   * Opens the delete confirmation dialog.
   *
   * @param {object} document The document to delete.
   */
  const handleDeleteDocument = (document) => {
    setDocumentToDelete(document);
    setIsDeleting(true);
  };

  /**
   * Deletes the selected document from Firestore.
   */
  const handleConfirmDelete = async () => {
    try {
      await deleteDoc(doc(db, "document", documentToDelete.id));
      // Update the state to remove the deleted document
      if (documentToDelete.type === "Lecture") {
        setLectureDocuments((prevDocs) =>
          prevDocs.filter((doc) => doc.id !== documentToDelete.id)
        );
      } else if (documentToDelete.type === "Personal") {
        setPersonalDocuments((prevDocs) =>
          prevDocs.filter((doc) => doc.id !== documentToDelete.id)
        );
      } else if (documentToDelete.type === "Collaborated") {
        setCollaboratedDocuments((prevDocs) =>
          prevDocs.filter((doc) => doc.id !== documentToDelete.id)
        );
      }

      setIsDeleting(false);
      setDocumentToDelete(null);
    } catch (error) {
      console.error("Failed to delete document:", error);
    }
  };

  /**
   * Closes the delete confirmation dialog.
   */
  const handleCancelDelete = () => {
    setIsDeleting(false);
    setDocumentToDelete(null);
  };

  /**
   * Opens the share document dialog.
   *
   * @param {object} document The document to share.
   */
  const handleShareDocument = (document) => {
    setDocumentToShare(document);
    setIsSharing(true);
  };

  /**
   * Closes the share document dialog.
   */
  const handleCloseShareDialog = () => {
    setIsSharing(false);
    setDocumentToShare(null);
  };

  // Generate share URL
  const shareUrl = documentToShare
    ? `${window.location.origin}/#document/${documentToShare.id}/${documentToShare.title}/${documentToShare.type}/${documentToShare.mode}/share`
    : "";

  // Handle copy link to clipboard
  const handleCopyLink = () => {
    setIsCopied(true);
    toast.success("Copied to clipboard!");
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <HelmetProvider>
      {" "}
      <Helmet>
        <title>Your Notes - Your Website Name</title>
        <meta
          name="description"
          content="Manage and access your lecture, personal, and collaborated notes with ease.  Generate summaries from audio recordings, YouTube videos, and articles."
        />
        <meta
          name="keywords"
          content="notes app, note taking, audio recording, summaries, collaboration, YouTube, articles"
        />
      </Helmet>
      <div className="notes">
        <div className="notesComp_header">
          <AudioRecorder />
        </div>
        <Container>
          <div className="notes_bottom">
            <div className="notes_bottom_header">
              <Tabs
                value={activeTab}
                onChange={handleChangeTab}
                aria-label="notes tabs"
              >
                <Tab label="Generated Summaries" value="Lecture" />
                <Tab label="Written Summaries" value="Personal" />
                <Tab label="Collaborated Summaries" value="Collaborated" />
              </Tabs>
            </div>
          </div>
        </Container>

        <Container sx={{ paddingTop: 10, minHeight: "70vh" }}>
          {isLoading ? (
            // Conditionally render the loading spinner
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px",
              }}
            >
              <Loading
                normalLoading={true}
                tittle={"Fetching your notes, please wait....."}
              />
            </Box>
          ) : (
            <Box mb={3}>
              {/* Render Lecture Documents */}
              {activeTab === "Lecture" && (
                <Box mb={3}>
                  {Object.keys(lectureDocumentsByMonth).length === 0 ? (
                    <Card
                      elevation={3}
                      style={{
                        padding: 16,
                        background: "#EFF4FF",
                        boxShadow: "none",
                        borderRadius: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "300px",
                      }}
                    >
                      <Typography variant="h5">
                        No Lecture Summaries Found
                      </Typography>
                    </Card>
                  ) : (
                    Object.keys(lectureDocumentsByMonth).map((month, index) => (
                      <div style={{ marginBottom: "20px" }} key={index}>
                        <Typography variant="h5">{month}</Typography>
                        <Grid
                          sx={{
                            paddingTop: 2,
                          }}
                          container
                          spacing={3}
                        >
                          {lectureDocumentsByMonth[month].map(
                            (note, noteIndex) => (
                              <Grid item xs={12} sm={6} md={4} key={noteIndex}>
                                <Card
                                  elevation={3}
                                  style={{
                                    padding: 16,
                                    background: "#EFF4FF",
                                    boxShadow: "none",
                                    borderRadius: 5,
                                    display: "flex",
                                    flexDirection: "column",
                                    cursor: "pointer",
                                  }}
                                >
                                  <Box
                                    onClick={() =>
                                      handleViewDocument(
                                        note.id,
                                        note.title,
                                        note.type,
                                        note.mode
                                      )
                                    }
                                    sx={{
                                      background: getRandomColor(),
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "150px",
                                      borderRadius: 2,
                                      marginBottom: 2,
                                    }}
                                  >
                                    <Description sx={{ fontSize: "5rem" }} />
                                  </Box>
                                  <Box
                                    sx={{
                                      flexGrow: 1,
                                    }}
                                  >
                                    <Typography color={"black"} variant="body1">
                                      {note?.title}
                                    </Typography>
                                    <Typography variant="body2">
                                      {note?.date}
                                    </Typography>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: 2,
                                      }}
                                    >
                                      <Chip
                                        onClick={() =>
                                          handleViewDocument(
                                            note.id,
                                            note.title,
                                            note.type,
                                            note.mode
                                          )
                                        }
                                        label={"View"}
                                        style={{
                                          backgroundColor: "#0C8CE9",
                                          color: "white",
                                          cursor: "pointer",
                                        }}
                                      />
                                      <Chip
                                        label={"Delete"}
                                        style={{
                                          backgroundColor: "red",
                                          color: "white",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleDeleteDocument(note)
                                        }
                                      />
                                      <Chip
                                        label={"Share"}
                                        style={{
                                          backgroundColor: "green",
                                          color: "white",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleShareDocument(note)
                                        }
                                      />
                                    </Box>
                                  </Box>
                                </Card>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </div>
                    ))
                  )}
                </Box>
              )}

              {/* Render Personal Documents */}
              {activeTab === "Personal" && (
                <Box mb={3}>
                  {Object.keys(personalDocumentsByMonth).length === 0 ? (
                    <Card
                      elevation={3}
                      style={{
                        padding: 16,
                        background: "#EFF4FF",
                        boxShadow: "none",
                        borderRadius: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "300px",
                      }}
                    >
                      <Typography variant="h5">
                        No Personal Summaries Found
                      </Typography>
                    </Card>
                  ) : (
                    Object.keys(personalDocumentsByMonth).map(
                      (month, index) => (
                        <div style={{ marginBottom: "20px" }} key={index}>
                          <Typography variant="h5">{month}</Typography>
                          <Grid
                            sx={{
                              paddingTop: 2,
                            }}
                            container
                            spacing={3}
                          >
                            {personalDocumentsByMonth[month].map(
                              (note, noteIndex) => (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  key={noteIndex}
                                >
                                  <Card
                                    elevation={3}
                                    style={{
                                      padding: 16,
                                      background: "#EFF4FF",
                                      boxShadow: "none",
                                      borderRadius: 5,
                                      display: "flex",
                                      flexDirection: "column",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Box
                                      onClick={() =>
                                        handleViewDocument(
                                          note.id,
                                          note.title,
                                          note.type,
                                          note.mode
                                        )
                                      }
                                      sx={{
                                        background: getRandomColor(),
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "150px",
                                        borderRadius: 2,
                                        marginBottom: 2,
                                      }}
                                    >
                                      <Description sx={{ fontSize: "5rem" }} />
                                    </Box>
                                    <Box
                                      sx={{
                                        flexGrow: 1,
                                      }}
                                    >
                                      <Typography
                                        color={"black"}
                                        variant="body1"
                                      >
                                        {note?.title}
                                      </Typography>
                                      <Typography variant="body2">
                                        {note?.date}
                                      </Typography>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          marginTop: 2,
                                        }}
                                      >
                                        <Chip
                                          onClick={() =>
                                            handleViewDocument(
                                              note.id,
                                              note.title,
                                              note.type,
                                              note.mode
                                            )
                                          }
                                          label={"View"}
                                          style={{
                                            backgroundColor: "#0C8CE9",
                                            color: "white",
                                            cursor: "pointer",
                                          }}
                                        />
                                        <Chip
                                          label={"Delete"}
                                          style={{
                                            backgroundColor: "red",
                                            color: "white",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handleDeleteDocument(note)
                                          }
                                        />
                                        <Chip
                                          label={"Share"}
                                          style={{
                                            backgroundColor: "green",
                                            color: "white",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handleShareDocument(note)
                                          }
                                        />
                                      </Box>
                                    </Box>
                                  </Card>
                                </Grid>
                              )
                            )}
                          </Grid>
                        </div>
                      )
                    )
                  )}
                </Box>
              )}

              {/* Render Collaborated Documents */}
              {activeTab === "Collaborated" && (
                <Box mb={3}>
                  {Object.keys(collaboratedDocumentsByMonth).length === 0 ? (
                    <Card
                      elevation={3}
                      style={{
                        padding: 16,
                        background: "#EFF4FF",
                        boxShadow: "none",
                        borderRadius: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "300px",
                      }}
                    >
                      <Typography variant="h5">
                        No Collaborated Summaries Found
                      </Typography>
                    </Card>
                  ) : (
                    Object.keys(collaboratedDocumentsByMonth).map(
                      (month, index) => (
                        <div style={{ marginBottom: "20px" }} key={index}>
                          <Typography variant="h5">{month}</Typography>
                          <Grid
                            sx={{
                              paddingTop: 2,
                            }}
                            container
                            spacing={3}
                          >
                            {collaboratedDocumentsByMonth[month].map(
                              (note, noteIndex) => (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  key={noteIndex}
                                >
                                  <Card
                                    elevation={3}
                                    style={{
                                      padding: 16,
                                      background: "#EFF4FF",
                                      boxShadow: "none",
                                      borderRadius: 5,
                                      display: "flex",
                                      flexDirection: "column",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Box
                                      onClick={() =>
                                        handleViewDocument(
                                          note.id,
                                          note.title,
                                          note.type,
                                          note.mode
                                        )
                                      }
                                      sx={{
                                        background: getRandomColor(),
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "150px",
                                        borderRadius: 2,
                                        marginBottom: 2,
                                      }}
                                    >
                                      <Description sx={{ fontSize: "5rem" }} />
                                    </Box>
                                    <Box
                                      sx={{
                                        flexGrow: 1,
                                      }}
                                    >
                                      <Typography
                                        color={"black"}
                                        variant="body1"
                                      >
                                        {note?.title}
                                      </Typography>
                                      <Typography variant="body2">
                                        {note?.date}
                                      </Typography>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          marginTop: 2,
                                        }}
                                      >
                                        <Chip
                                          onClick={() =>
                                            handleViewDocument(
                                              note.id,
                                              note.title,
                                              note.type,
                                              note.mode
                                            )
                                          }
                                          label={"View"}
                                          style={{
                                            backgroundColor: "#0C8CE9",
                                            color: "white",
                                            cursor: "pointer",
                                          }}
                                        />
                                        <Chip
                                          label={"Delete"}
                                          style={{
                                            backgroundColor: "red",
                                            color: "white",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handleDeleteDocument(note)
                                          }
                                        />
                                        <Chip
                                          label={"Share"}
                                          style={{
                                            backgroundColor: "green",
                                            color: "white",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handleShareDocument(note)
                                          }
                                        />
                                      </Box>
                                    </Box>
                                  </Card>
                                </Grid>
                              )
                            )}
                          </Grid>
                        </div>
                      )
                    )
                  )}
                </Box>
              )}
            </Box>
          )}
        </Container>

        {/* Dialog for confirming document deletion */}
        <Dialog
          open={isDeleting}
          onClose={handleCancelDelete}
          maxWidth="xs"
          fullWidth={true}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this document?
          </DialogContent>
          <DialogActions>
            <button onClick={handleCancelDelete} className="dialogue_cancel">
              Cancel
            </button>
            <button onClick={handleConfirmDelete} className="dialogue_success">
              Confirm
            </button>
          </DialogActions>
        </Dialog>

        {/* Dialog for sharing a document */}
        <Dialog
          open={isSharing}
          onClose={handleCloseShareDialog}
          maxWidth="xs"
          fullWidth={true}
        >
          <DialogTitle>Share Document</DialogTitle>
          <DialogContent>
            <Box display="flex" justifyContent="space-around" marginBottom={2}>
              <FacebookShareButton url={shareUrl}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <LinkedinShareButton url={shareUrl}>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <EmailShareButton url={shareUrl}>
                <EmailIcon size={32} round />
              </EmailShareButton>
              <WhatsappShareButton url={shareUrl}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <CopyToClipboard text={shareUrl} onCopy={handleCopyLink}>
                <Chip
                  label={isCopied ? "Copied!" : "Copy Link"}
                  style={{ cursor: "pointer" }}
                />
              </CopyToClipboard>
            </Box>
          </DialogContent>
          <DialogActions>
            <button
              onClick={handleCloseShareDialog}
              className="dialogue_cancel"
            >
              Close
            </button>
          </DialogActions>
        </Dialog>
      </div>
    </HelmetProvider>
  );
};

export default Home;

/**
 * Groups an array of documents by month and year.
 *
 * @param {Array<object>} data An array of documents.
 * @returns {object} An object containing grouped documents by month and year.
 */
function groupByMonth(data) {
  if (!Array.isArray(data) || data.length === 0) {
    return {};
  }

  const months = {
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
  };

  return data.reduce((acc, item) => {
    const date = new Date(item.date);
    const monthYear = `${months[date.getMonth()]} ${date.getFullYear()}`;
    if (!acc[monthYear]) {
      acc[monthYear] = [];
    }
    acc[monthYear].push(item);
    return acc;
  }, {});
}
