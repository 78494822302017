import React, { useState } from "react";
import Image from "../../assets/image.png";
import Logo from "../../assets/logo.png";
import GoogleSvg from "../../assets/icons8-google.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "../../config/firebase";
import * as constants from "../../utils/constants/index";
import api from "../../utils/fetch-data/api";
import { Alert, CircularProgress } from "@mui/material";
import { setCookieToken, storeToken } from "../../utils/ProtectedRoute";

const SignUp = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const from = location.state?.prevUrl || "/";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!formValues.name || !formValues.email || !formValues.password) {
      toast.error("Please fill in all the inputs");
      return;
    }

    if (formValues.password !== formValues.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    const { name, email, password } = formValues;

    setLoading(true);
    try {
      const response = await api.post(constants.SIGNUP_ENDPOINT, {
        name,
        email,
        password,
      });

      if (response?.status === 200) {
        storeToken(response.data);
        setCookieToken(response.data.jwtToken);
        toast.success("You have successfully signed up.");
        navigate(from);
        setFormValues({
          name: "",
          email: "",
          password: "",
          confirmPassword: "",
        });
      } else {
        toast.error("Sign up failed");
      }
    } catch (error) {
      setError(
        error || "There was an error with the sign up. Please try again."
      ) || toast.error(error.error);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignUp = async () => {
    const provider = new GoogleAuthProvider();
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const idToken = await user.getIdToken();

      // Send the ID token to your server
      const response = await api.post(constants.GOOGLE_SIGNUP_ENDPOINT, {
        idToken,
      });

      // Handle the response from your server
      if (response.status === 200) {
        storeToken(response.data);
        setCookieToken(response.data.jwtToken);
        toast.success("You have successfully signed up.");
        navigate(from);
      } else {
        toast.error("Google sign-up failed");
      }
    } catch (error) {
      setError(
        error || "There was an error with the sign up. Please try again."
      ) || toast.error(error.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-main">
      <div className="login-left">
        <img src={Image} alt="" />
      </div>
      <div className="login-right">
        <div className="login-right-container">
          <div className="login-logo">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="login-center">
            <h2>Welcome</h2>
            <p>Please enter your details</p>
            {error && (
              <Alert
                sx={{ marginBottom: 5, textAlign: "center" }}
                severity="error"
              >
                <p>{error.error}</p>
              </Alert>
            )}
            <form onSubmit={handleSignUp}>
              <input
                type="text"
                name="name"
                placeholder="Full Name"
                value={formValues.name}
                onChange={handleInputChange}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formValues.email}
                onChange={handleInputChange}
                required
              />
              <div className="pass-input-div">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="New Password"
                  value={formValues.password}
                  onChange={handleInputChange}
                  required
                />
                {showPassword ? (
                  <VisibilityOff
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <Visibility
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
              <div className="pass-input-div">
                <input
                  type={showPassword ? "text" : "password"}
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={formValues.confirmPassword}
                  onChange={handleInputChange}
                  required
                />
                {showPassword ? (
                  <VisibilityOff
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <Visibility
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
              <div className="login-center-buttons">
                <button type="submit" disabled={loading}>
                  {loading ? <CircularProgress size={24} /> : "Sign Up"}
                </button>
                <button
                  type="button"
                  onClick={handleGoogleSignUp}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <>
                      <img src={GoogleSvg} alt="Google" />
                      Sign Up with Google
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>

          <p className="login-bottom-p">
            Do you have an account? <Link to="/login">Log In</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
