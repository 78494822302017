import axios from "axios";
import { BASE_URL } from "../constants";

const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      console.error("Response Error:", error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      console.error("Request Error:", error.request);
      return Promise.reject(error.request);
    } else {
      console.error("Error:", error.message);
      return Promise.reject(error.message);
    }
  }
);

export default api;
