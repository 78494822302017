import { get, post, put, del } from "./http-requests";

export const fetchData = async (setData, endpoint, params = {}) => {
  const result = await get(endpoint, params);
  setData(result);
};

export const createItem = async (endpoint, item) => {
  return await post(endpoint, item);
};

export const updateItem = async (item, setData, endpoint) => {
  await put(endpoint, item);
};

export const deleteItem = async (endpoint, params = {}) => {
  await del(endpoint, params);
};
