import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dialog, DialogTitle, DialogActions, Chip } from "@mui/material";
import Logo from "../../assets/logo.png";
import Image from "../../assets/image.png";
import "./ForgetPassword.css";
import { toast } from "react-toastify";
import * as constants from "../../utils/constants/index";
import { createItem } from "../../utils/fetch-data/FetchData";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    email: "",
    otp: "",
    password: "",
  });
  const [step, setStep] = useState(1); // 1: Email submission, 2: OTP and password reset
  const [dialogOpen, setDialogOpen] = useState(false);
  const [emailSent, setEmailSent] = useState(false); // Track email sent status
  const location = useLocation();
  const from = location.state?.prevUrl || "/";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!formValues.email) {
      toast.error("Please enter your email address");
      return;
    }

    try {
      const response = await createItem(
        constants.RESET_PASSWORD_REQUEST_ENDPOINT,
        {
          email: formValues.email,
        }
      );
      if (response.status === 200) {
        setEmailSent(true); // Mark email as sent
        setStep(2); // Move to OTP and password reset step
        toast.success(
          "An email has been sent with instructions to reset your password."
        );
      } else {
        toast.error(response.message || "Error sending email");
      }
    } catch (error) {
      toast.error(error.message || "Error sending email");
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!formValues.otp || !formValues.password) {
      toast.error("Please fill in all the inputs");
      return;
    }

    try {
      const response = await createItem(constants.RESET_PASSWORD_ENDPOINT, {
        email: formValues.email,
        otp: formValues.otp,
        password: formValues.password,
      });
      if (response.status === 200) {
        setDialogOpen(true);
      } else {
        toast.error(response.message || "Error resetting password");
      }
    } catch (error) {
      toast.error(error.message || "Error resetting password");
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    navigate("/login", { state: { prevUrl: from } });
  };

  const renderEmailStep = () => (
    <div className="firstRender">
      <p>
        Enter your email address below to receive instructions for resetting
        your password.
      </p>
      <form onSubmit={handleEmailSubmit}>
        <input
          type="email"
          placeholder="Email Address"
          name="email"
          value={formValues.email}
          onChange={handleInputChange}
        />
        <div className="login-center-buttons">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );

  const renderPasswordResetStep = () => (
    <div className="secondRender">
      <p
        style={{
          fontFamily: "Arial, sans-serif",
          fontSize: "20px",
          color: "#333",
          lineHeight: 1.5,
          backgroundColor: "#f9f9f9",
          borderLeft: "5px solid #007bff",
          padding: "15px",
          borderRadius: "5px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          minHeight: 400,
          display: "flex",
          alignItems: "center",
        }}
      >
        We have sent a password reset link to your email address. Please check
        your inbox and follow the instructions provided to reset your password.
        Once you have received the OTP, enter it below along with your new
        password to complete the reset process.
      </p>

      {/* <form onSubmit={handleResetPassword}>
        <input
          type="text"
          placeholder="OTP"
          name="otp"
          value={formValues.otp}
          onChange={handleInputChange}
        />
        <input
          type="password"
          placeholder="New Password"
          name="password"
          value={formValues.password}
          onChange={handleInputChange}
        />
        <div className="login-center-buttons">
          <button type="submit">Reset Password</button>
        </div>
      </form> */}
    </div>
  );

  const successRender = () => (
    <Dialog
      sx={{ minWidth: "500px" }}
      maxWidth="xl"
      open={dialogOpen}
      onClose={handleDialogClose}
    >
      <DialogTitle sx={{ minWidth: "500px", color: "#5551ff" }}>
        Your password has been reset successfully.
      </DialogTitle>
      <DialogActions>
        <Chip
          label="Done"
          style={{
            marginTop: 8,
            backgroundColor: "#0C8CE9",
            color: "white",
            cursor: "pointer",
          }}
          onClick={handleDialogClose}
        />
        <Chip
          label="Cancel"
          style={{
            marginTop: 8,
            backgroundColor: "red",
            color: "white",
            cursor: "pointer",
            marginLeft: 8,
          }}
          onClick={handleDialogClose}
        />
      </DialogActions>
    </Dialog>
  );

  return (
    <div className="login-main">
      <div className="login-left">
        <img src={Image} alt="" />
      </div>
      <div style={{ maxWidth: "700px" }} className="login-right">
        <div className="login-right-container">
          <div className="login-logo">
            <img src={Logo} alt="" />
          </div>
          <div className="login-center">
            {step === 1 ? renderEmailStep() : renderPasswordResetStep()}
          </div>
          <p className="login-bottom-p">
            Back to <Link to="/login">Log In</Link>
          </p>
        </div>
      </div>
      {successRender()}
    </div>
  );
};

export default ForgetPassword;
