import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  TextField,
  InputAdornment,
  CircularProgress,
  Paper,
  Divider,
  Grid,
  Button,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import EditIcon from "@mui/icons-material/Edit";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { marked } from "marked";
import * as constants from "../../utils/constants/index";
import api from "../../utils/fetch-data/api";
import { db } from "../../config/firebase";
import { updateDoc, serverTimestamp, doc } from "firebase/firestore";
import TurndownService from "turndown";

const DocumentCharBox = ({ quill, documentId }) => {
  const documentRef = doc(db, "document", documentId);
  const [chartData, setChartData] = useState("");
  const [loading, setLoading] = useState(false);
  const [geminiChartResponse, setGeminiChartResponse] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const chatEndRef = useRef(null);
  const [isChatting, setIsChatting] = useState(false);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleChartDataChange = (event) => {
    setChartData(event.target.value);
  };

  const convertHtmlToMarkdown = (html) => {
    const turndownService = new TurndownService();
    return turndownService.turndown(`${html}`);
  };

  // Assuming you have the HTML content
  const justHtml = quill && quill.root.innerHTML;

  // Convert the HTML to Markdown
  const editor_content = convertHtmlToMarkdown(justHtml);

  const handleCreateChart = useCallback(async () => {
    try {
      setLoading(true);
      const postData = {
        text: editor_content,
        action: "rewrite the whole document. ",
        prompt: chartData,
      };

      const response = await api.post(constants.AI_GENERATE_ENDPOINT, postData);
      const data = response.data;
      setLoading(false);
      setGeminiChartResponse(data);
      setChartData("");
    } catch (error) {
      console.error("Error asking AI:", error.message);
      setLoading(false);
    }
  }, [chartData, quill]);

  useEffect(() => {
    if (openDrawer) {
      chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [openDrawer]);

  useEffect(() => {
    if (openDrawer) {
      setTimeout(() => {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }, [openDrawer]);
  const handleDiscardChart = () => {
    setGeminiChartResponse(null);
    setChartData("");
    handleCloseDrawer();
  };
  const styleDocumentContent = (htmlContent) => {
    // Example styling functions
    const styleTitles = (html) =>
      html.replace(/<h([1-6])>(.*?)<\/h\1>/gi, (match, p1, p2) => {
        const size = parseInt(p1, 10);
        return `<h${p1} style="font-size:${2 + (6 - size)}rem;">${p2}</h${p1}>`;
      });

    const styleLinks = (html) =>
      html.replace(/<a href="(.*?)">(.*?)<\/a>/gi, (match, p1, p2) => {
        return `<a href="${p1}" style="color:#007bff; text-decoration:none;">${p2}</a>`;
      });

    const styleLists = (html) =>
      html
        .replace(/<ul>(.*?)<\/ul>/gi, (match, p1) => {
          return `<ul style="list-style-type: disc; padding-left: 20px;">${p1}</ul>`;
        })
        .replace(/<ol>(.*?)<\/ol>/gi, (match, p1) => {
          return `<ol style="list-style-type: decimal; padding-left: 20px;">${p1}</ol>`;
        });

    const styleParagraphs = (html) =>
      html.replace(/<p>(.*?)<\/p>/gi, (match, p1) => {
        return `<p style="margin: 0 0 10px 0;">${p1}</p>`;
      });

    let styledHtml = styleTitles(htmlContent);
    styledHtml = styleLinks(styledHtml);
    styledHtml = styleLists(styledHtml);
    styledHtml = styleParagraphs(styledHtml);

    return styledHtml;
  };

  const handleReplaceChart = () => {
    if (quill && geminiChartResponse) {
      // Style the HTML content
      const markedResponse = marked(geminiChartResponse.text);
      const styledContent = styleDocumentContent(markedResponse);

      // Apply styling directly to the Quill Delta
      const delta = quill.clipboard.dangerouslyPasteHTML(styledContent);
      quill.updateContents(delta, "silent");
      // Save the changes to Firestore
      const content = quill.getContents();
      updateDoc(documentRef, {
        data: JSON.stringify(content),
        updatedAt: serverTimestamp(),
      });
    }
    handleDiscardChart();
  };

  return (
    <>
      <button className="recorder_button" onClick={() => setOpenDrawer(true)}>
        <AutoAwesomeIcon />
        <span> Document Chat</span>
      </button>
      <Drawer
        anchor="bottom"
        open={openDrawer}
        onClose={handleCloseDrawer}
        PaperProps={{
          style: {
            maxWidth: "90vw",
            maxHeight: "70vh",
            margin: "auto",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Grid item xs={12} style={{ flex: 1, overflowY: "auto" }}>
          <div style={{ padding: "16px", flex: 1 }}>
            {geminiChartResponse && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "16px",
                }}
              >
                <Paper
                  elevation={3}
                  style={{
                    padding: "16px",
                    textAlign: "start",
                    marginRight: "auto",
                    backgroundColor: "#e0f7fa",
                    borderRadius: "8px",
                    maxWidth: "100%",
                    minWidth: "80%",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    alignItems: "flex-end",
                    marginTop: 10,
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{
                      whiteSpace: "pre-wrap",
                      fontFamily: "'Roboto', sans-serif",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: marked(geminiChartResponse.text),
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "16px",
                    }}
                  >
                    <button
                      onClick={handleDiscardChart}
                      style={{
                        backgroundColor: "#A855F7",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        padding: "8px 12px",
                        cursor: "pointer",
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <EditIcon style={{ marginRight: "4px" }} />
                      Discard
                    </button>
                    <button
                      onClick={handleReplaceChart}
                      style={{
                        backgroundColor: "#A855F7",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        padding: "8px 12px",
                        cursor: "pointer",
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Replace
                    </button>
                  </div>
                  <Divider style={{ margin: "16px 0" }} />
                </Paper>
              </div>
            )}
            <div ref={chatEndRef} />
          </div>
        </Grid>
        <div
          style={{
            padding: "16px",
            background: "#ffff",
            borderTop: "1px solid #ddd",
            boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <TextField
            fullWidth
            placeholder={
              isChatting
                ? "Ask the AI about charts..."
                : "Write your chart data here..."
            }
            multiline
            rows={3}
            variant="outlined"
            value={chartData}
            onChange={handleChartDataChange}
            disabled={loading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleCreateChart}
                    style={{
                      color: "#fff",
                      fontSize: "2rem",
                      background: "#a855f7",
                      padding: 10,
                      borderRadius: "50%",
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <ArrowUpwardIcon size={24} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Drawer>
    </>
  );
};

export default DocumentCharBox;
