import React, { useEffect } from "react";
import "./App.css";
import { createHashRouter, RouterProvider } from "react-router-dom";
import Layout from "./utils/Layout/Layout";
import Document from "./pages/document/Document";
import Home from "./pages/Home/Home";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";
import ForgetPassword from "./pages/auth/ForgetPassword";
import Landing from "./pages/landing-page/Landing";
import { ProtectedRoute, RedirectLoggedIn } from "./utils/ProtectedRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { userID } from "./utils/getAccessToken";

const router = createHashRouter([
  {
    path: "/",
    children: [
      {
        path: "",
        element: (
          <ProtectedRoute fallback={<Landing />}>
            <Layout>{userID ? <Home /> : <Landing />}</Layout>
          </ProtectedRoute>
        ),
      },
      {
        path: "login",
        element: (
          <RedirectLoggedIn>
            <Login />
          </RedirectLoggedIn>
        ),
      },
      {
        path: "sign-up",
        element: (
          <RedirectLoggedIn>
            <SignUp />
          </RedirectLoggedIn>
        ),
      },
      {
        path: "forgot-password",
        element: (
          <RedirectLoggedIn>
            <ForgetPassword />
          </RedirectLoggedIn>
        ),
      },
      {
        path: "audio/:id",
        element: (
          <ProtectedRoute fallback={<Landing />}>
            <Layout>
              <Home />
            </Layout>
          </ProtectedRoute>
        ),
      },
      {
        path: "document/:id/:title/:type/:mode/:action",
        element: (
          <ProtectedRoute fallback={<Landing />}>
            <Document />
          </ProtectedRoute>
        ),
      },
      {
        path: "document/:id/:title/:type/:mode",
        element: (
          <ProtectedRoute fallback={<Landing />}>
            <Document />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

function App() {
  return (
    <>
      <div className="app">
        <RouterProvider router={router} />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    </>
  );
}

export default App;
