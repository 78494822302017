export const BASE_URL = "https://server.typeabc.com";
export const GET_DOCUMENTS_ENDPOINT = "/api/v1/documents";
export const DELETE_DOCUMENT_ENDPOINT = "/api/v1/documents";
export const LOGIN_ENDPOINT = "/api/v1/auth/login";
export const REFRESH_TOKEN_ENDPOINT = "/api/v1/auth/refresh-token";
export const SIGNUP_ENDPOINT = "/api/v1/auth/register";
export const GOOGLE_LOGIN_ENDPOINT = "/api/v1/auth/google-login";
export const GOOGLE_SIGNUP_ENDPOINT = "/api/v1/auth/google-register";
export const RESET_PASSWORD_ENDPOINT = "/api/v1/auth/reset-password";
export const LOGOUT_ENDPOINT = "/api/v1/auth/logout";
export const UPLOAD_AUDIO_ENDPOINT = "/api/v1/audios/upload-audio";
export const AI_GENERATE_ENDPOINT = "/api/v1/documents/generate";
export const AI_CHART_ENDPOINT = "/api/v1/documents/chart";
export const RESET_PASSWORD_REQUEST_ENDPOINT = "/api/v1/auth/reset-password";
export const UPLOAD_URL_ENDPOINT = "/api/v1/audios/upload-url";
export const PROCESS_ARTICLE_ENDPOINT = "/api/v1/audios/process-article";
export const PROCESS_YOUTUBE_ENDPOINT = "/api/v1/audios/process-youtube";
export const PROCESS_CONTENT_ENDPOINT = "/api/v1/documents/content";
export const GET_USERS_ENDPOINT = "/api/v1/auth/users";
export const GET_USER_ENDPOINT = "/api/v1/auth/user";
