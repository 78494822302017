import React, { useState, useRef } from "react";
import {
  Dialog,
  Slide,
  Chip,
  Typography,
  Divider,
  Paper,
  Toolbar,
  AppBar,
  Box,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import { marked } from "marked";
import { toast } from "react-toastify";
import Loading from "../loading/Loading";
import api from "../../utils/fetch-data/api";
import axios from "axios";
import * as constants from "../../utils/constants/index";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InsertToDocument = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [linkInputValue, setLinkInputValue] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [contentResponse, setContentResponse] = useState(null);
  const [copied, setCopied] = useState(false);

  const audioFileInputRef = useRef(null);
  const pdfFileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setOpenDrawer(true);
    await handleUpload(file);
  };

  const handleUpload = async (file) => {
    if (!file) return;

    try {
      const response = await api.get(constants.UPLOAD_URL_ENDPOINT, {
        params: {
          fileName: file.name,
          fileType: file.type,
        },
      });

      const { uploadUrl, publicUrl, objectName } = response.data;

      if (!uploadUrl) {
        throw new Error("Upload URL is undefined");
      }

      await axios.put(uploadUrl, file, {
        headers: {
          "Content-Type": file.type,
          "Content-Disposition": "inline",
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setUploadProgress(progress);
        },
      });
      setLoading(true);
      const fileType = MIMEType(file);

      if (fileType === "unknown") {
        toast.error(
          "Unsupported file type. Please upload a valid audio or document file.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        return;
      }

      const rep = await api.post(constants.PROCESS_CONTENT_ENDPOINT, {
        content: objectName,
        MIMEtype: fileType,
      });
      setContentResponse(rep.data.summaryText);
      setLoading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setOpenDrawer(false);
      setLoading(false);
      toast.error("File upload failed. Please try again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const triggerAudioFileInput = () => {
    audioFileInputRef.current.click();
  };

  const triggerPdfFileInput = () => {
    pdfFileInputRef.current.click();
  };

  const handleGenerateSummary = async () => {
    let isYouTubeLink = false;
    if (
      linkInputValue.includes("youtube.com") ||
      linkInputValue.includes("youtu.be")
    ) {
      isYouTubeLink = true;
    }
    if (!linkInputValue) {
      toast.error("Cannot submit an empty link");
      return;
    }
    try {
      setLoading(true);

      const response = await api.post(constants.PROCESS_CONTENT_ENDPOINT, {
        content: linkInputValue,
      });
      setContentResponse(response.data.summaryText);
    } catch (error) {
      console.error(
        `Error processing ${isYouTubeLink ? "YouTube" : "article"} link:`,
        error
      );
      toast.error(
        `Error processing ${
          isYouTubeLink ? "YouTube" : "article"
        } link. Please try again.`
      );
    } finally {
      setLoading(false);
    }
  };

  const MIMEType = (file) => {
    const extension = file.type.split("/")[1];
    switch (extension) {
      case "aac":
        return "audio/aac";
      case "flac":
        return "audio/flac";
      case "mp3":
        return "audio/mp3";
      case "m4a":
        return "audio/m4a";
      case "mpeg":
        return "audio/mpeg";
      case "mpga":
        return "audio/mpga";
      case "mp4":
        return "audio/mp4";
      case "opus":
        return "audio/opus";
      case "pcm":
        return "audio/pcm";
      case "wav":
        return "audio/wav";
      case "webm":
        return "audio/webm";
      case "flv":
        return "video/x-flv";
      case "mov":
        return "video/mov";
      case "mpeg":
        return "video/mpeg";
      case "mpegps":
        return "video/mpegps";
      case "mpg":
        return "video/mpeg";
      case "mp4":
        return "video/mp4";
      case "webm":
        return "video/webm";
      case "wmv":
        return "video/wmv";
      case "3gpp":
        return "video/3gpp";
      case "pdf":
        return "application/pdf";
      default:
        return "unknown";
    }
  };

  const handleCopy = () => {
    if (navigator.clipboard) {
      const el = document.createElement("div");
      el.innerHTML = contentResponse;
      document.body.appendChild(el);
      const range = document.createRange();
      range.selectNode(el);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      navigator.clipboard.writeText(el.innerHTML).then(
        () => {
          setCopied(true);
          setOpenDrawer(false);
          setTimeout(() => setCopied(false), 2000);
        },
        (err) => {
          console.error("Failed to copy: ", err);
        }
      );
      window.getSelection().removeAllRanges();
      document.body.removeChild(el);
    } else {
      toast.error("Clipboard API is not supported in your browser.");
    }
  };

  const handleDiscard = () => {
    setOpenDrawer(false);
    setLoading(false);
    setLinkInputValue("");
    setUploadProgress(0);
    setContentResponse(null);
  };

  const handleBack = () => {
    setLoading(false);
    setLinkInputValue("");
    setUploadProgress(0);
    setContentResponse(null);
  };

  return (
    <>
      <button className="recorder_button" onClick={() => setOpenDrawer(true)}>
        <CloudUploadIcon />
        <span>Insert / upload</span>
      </button>

      <Dialog
        open={openDrawer}
        maxWidth="md"
        fullWidth
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            maxHeight: "80vh",
            textAlign: "center",
            background: "#eff4ff",
            minHeight: "80vh",
          },
        }}
      >
        <AppBar position="static" style={{ background: "white" }}>
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Chip
              onClick={handleBack}
              label="Back"
              style={{
                backgroundColor: "#5551ff",
                color: "white",
                cursor: "pointer",
                fontSize: "1rem",
              }}
            />
            <Chip
              onClick={() => setOpenDrawer(false)}
              label="close"
              style={{
                backgroundColor: "red",
                color: "white",
                cursor: "pointer",
                fontSize: "1rem",
              }}
            />
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <>
              {uploadProgress < 100 && uploadProgress > 1 && (
                <>
                  <Loading
                    normalLoading={true}
                    tittle={`Uploading File... (${uploadProgress}%)`}
                  />
                </>
              )}
              <Loading
                normalLoading={false}
                tittle={"Ai is thinking. Please wait..."}
              />
            </>
          ) : (
            <>
              {contentResponse ? (
                <div>
                  <Paper
                    elevation={3}
                    style={{
                      padding: "16px",
                      textAlign: "start",
                      marginRight: "auto",
                      backgroundColor: "#e0f7fa",
                      borderRadius: "8px",
                      maxWidth: "100%",
                      minWidth: "80%",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      alignItems: "flex-end",
                      marginTop: 10,
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        padding: 5,
                        whiteSpace: "pre-wrap",
                        fontFamily: "'Roboto', sans-serif",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: marked(contentResponse),
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "16px",
                      }}
                    >
                      <button
                        onClick={handleDiscard}
                        style={{
                          backgroundColor: "#A855F7",
                          color: "#fff",
                          border: "none",
                          borderRadius: "4px",
                          padding: "8px 12px",
                          cursor: "pointer",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <EditIcon style={{ marginRight: "4px" }} />
                        Discard
                      </button>
                      <button
                        onClick={handleCopy}
                        style={{
                          backgroundColor: "#A855F7",
                          color: "#fff",
                          border: "none",
                          borderRadius: "4px",
                          padding: "8px 12px",
                          cursor: "pointer",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ContentCopyIcon style={{ marginRight: "4px" }} />
                        Copy
                      </button>
                    </div>
                    {copied && (
                      <Typography
                        color="secondary"
                        style={{
                          marginTop: "8px",
                          fontSize: "12px",
                          fontStyle: "italic",
                        }}
                      >
                        Copied!
                      </Typography>
                    )}
                    <Divider style={{ margin: "16px 0" }} />
                  </Paper>
                </div>
              ) : (
                <div className="audio_recorder">
                  <div className="recorder_buttons">
                    <>
                      <button
                        className="recorder_button"
                        onClick={triggerAudioFileInput}
                      >
                        <FileUploadIcon
                          sx={{ fontSize: "2rem", color: "white" }}
                        />
                        Upload an Audio
                      </button>
                      <input
                        type="file"
                        ref={audioFileInputRef}
                        accept="audio/aac,audio/flac,audio/mp3,audio/m4a,audio/mpeg,audio/mpga,audio/mp4,audio/opus,audio/pcm,audio/wav,audio/webm"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                      />
                    </>
                    <>
                      <button
                        className="recorder_button"
                        onClick={triggerPdfFileInput}
                      >
                        <FileUploadIcon
                          sx={{ fontSize: "2rem", color: "white" }}
                        />
                        Upload a Document
                      </button>
                      <input
                        type="file"
                        ref={pdfFileInputRef}
                        accept=".pdf"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                      />
                    </>
                  </div>
                  <div className="document_inputs">
                    <>
                      <div className="link-input-div">
                        <input
                          name="text"
                          autoComplete="off"
                          placeholder="Paste YouTube or Article Link"
                          value={linkInputValue}
                          onChange={(e) => setLinkInputValue(e.target.value)}
                          onKeyUp={(event) => {
                            if (event.key === "Enter") {
                              handleGenerateSummary();
                            }
                          }}
                        />
                        <button
                          className="recorder_button"
                          onClick={handleGenerateSummary}
                        >
                          <AddCircleIcon
                            sx={{ fontSize: "2rem", color: "white" }}
                          />
                          Generate
                        </button>
                      </div>
                    </>
                  </div>
                </div>
              )}
            </>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default InsertToDocument;
