import TextEditor from "../../components/text-editor";
import "./Document.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Document = () => {
  return (
    <HelmetProvider>
      <div className="document">
        <Helmet>
          <title>typeAbc</title>
          <meta
            name="description"
            content="Create, edit, and collaborate on rich text documents with advanced features like AI assistance, image upload, and Word document export."
          />
          <meta
            name="keywords"
            content="text editor, document editor, collaborative editing, AI writing, image upload, word export"
          />
        </Helmet>

        <TextEditor />
      </div>
    </HelmetProvider>
  );
};

export default Document;
