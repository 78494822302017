import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  TextField,
  InputAdornment,
  CircularProgress,
  Paper,
  Divider,
  Grid,
  Button,
  useMediaQuery,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import CopyToClipboard from "react-copy-to-clipboard";
import Loading from "../loading/Loading";
import { marked } from "marked";
import * as constants from "../../utils/constants/index";
import api from "../../utils/fetch-data/api";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { db } from "../../config/firebase";
import {
  collection,
  addDoc,
  getDocs,
  query,
  orderBy,
  where,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { handleLogout } from "../../utils/getAccessToken";

const CharBox = ({ quill }) => {
  const [chartData, setChartData] = useState("");
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [geminiChartResponse, setGeminiChartResponse] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const chatEndRef = useRef(null);
  const [isChatting, setIsChatting] = useState(false);
  const accessToken = localStorage.getItem("accessToken");
  const userID = accessToken ? accessToken : handleLogout;

  // Use Media Query to determine if screen is 700px or less
  const isSmallScreen = useMediaQuery("(max-width:700px)");

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleChartDataChange = (event) => {
    setChartData(event.target.value);
  };

  const handleCreateChart = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.post(constants.AI_CHART_ENDPOINT, {
        prompt: chartData,
        userID,
      });
      const data = response.data;
      setLoading(false);
      setGeminiChartResponse(data);
      setChartData("");

      fetchChatHistory();
    } catch (error) {
      console.error("Error asking AI:", error.message);
      setLoading(false);
    }
  }, [chartData, userID]);

  const fetchChatHistory = async () => {
    try {
      const q = query(
        collection(db, "charts"),
        where("userID", "==", userID),
        orderBy("timestamp", "asc")
      );
      const querySnapshot = await getDocs(q);
      const history = querySnapshot.docs.map((doc) => doc.data());
      setChatHistory(history);
    } catch (error) {
      toast.error("Error fetching chat history:" || error.message);
    }
  };

  useEffect(() => {
    fetchChatHistory();
  }, []);

  useEffect(() => {
    if (openDrawer) {
      chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatHistory, openDrawer]);

  useEffect(() => {
    if (openDrawer) {
      setTimeout(() => {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }, [openDrawer]);

  const handleInsertChartBelow = () => {
    if (quill && geminiChartResponse) {
      const selection = quill.getSelection();
      quill.clipboard.dangerouslyPasteHTML(
        selection?.index + selection?.length,
        marked(geminiChartResponse.text)
      );
    }
  };

  const clearChatHistory = async () => {
    try {
      const q = query(collection(db, "charts"), where("userID", "==", userID));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        deleteDoc(doc.ref);
      });
      setChatHistory([]);
      setIsChatting(false);
    } catch (error) {
      toast.error("Error clearing chat history:" || error.message);
    }
  };

  const newChart = async () => {
    try {
      const q = query(collection(db, "charts"), where("userID", "==", userID));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        deleteDoc(doc.ref);
      });
      setChatHistory([]);
      setIsChatting(false);
    } catch (error) {
      toast.error("Error creating a new chat:" || error.message);
    }
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };
  return (
    <>
      <button className="recorder_button" onClick={() => setOpenDrawer(true)}>
        <AutoAwesomeIcon />
        <span> AI Chat </span>
      </button>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleCloseDrawer}
        PaperProps={{
          style: {
            minWidth: isSmallScreen ? "100%" : "40vw",
            maxWidth: isSmallScreen ? "100%" : "70vw",
            textAlign: "center",
            background: "white",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <AppBar position="static" style={{ background: "white" }}>
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{
                background: "red",
                color: "white",
                padding: "5px 15px",
              }}
              onClick={handleCloseDrawer}
              startIcon={<CloseIcon />}
            >
              Close
            </Button>
            <div>
              <Button
                style={{
                  background: "#A855F7",
                  color: "white",
                  padding: "5px 15px",
                }}
                onClick={clearChatHistory}
              >
                Clear Chats
              </Button>
              {/* <Button
                style={{
                  background: "#0c8ce9",
                  color: "white",
                  padding: "5px 15px",
                  marginLeft: 10,
                }}
                color="primary"
                onClick={newChart}
              >
                New Chat
              </Button> */}
            </div>
          </Toolbar>
        </AppBar>
        <div style={{ flex: 1, overflow: "auto", padding: "16px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "right" }}></Grid>
            <Grid item xs={12}>
              <div style={{ padding: "16px" }}>
                {chatHistory.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "16px",
                    }}
                  >
                    <Paper
                      elevation={3}
                      style={{
                        padding: "16px",
                        textAlign: "start",
                        marginLeft: "auto",
                        backgroundColor: "white",
                        borderRadius: "8px",
                        maxWidth: "80%",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          whiteSpace: "pre-wrap",
                          fontFamily: "'Roboto', sans-serif",
                        }}
                      >
                        {item.prompt}
                      </Typography>
                    </Paper>
                    <Paper
                      elevation={3}
                      style={{
                        padding: "16px",
                        textAlign: "start",
                        marginRight: "auto",
                        backgroundColor: "#e0f7fa",
                        borderRadius: "8px",
                        maxWidth: "80%",
                        minWidth: "80%",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        alignItems: "flex-end",
                        marginTop: 10,
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          whiteSpace: "pre-wrap",
                          fontFamily: "'Roboto', sans-serif",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: marked(item.response),
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "16px",
                        }}
                      >
                        <button
                          onClick={handleInsertChartBelow}
                          style={{
                            backgroundColor: "#A855F7",
                            color: "#fff",
                            border: "none",
                            borderRadius: "4px",
                            padding: "8px 12px",
                            cursor: "pointer",
                            fontSize: "14px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <EditIcon style={{ marginRight: "4px" }} />
                          Insert Below
                        </button>
                        <CopyToClipboard
                          text={geminiChartResponse?.text}
                          onCopy={handleCopy}
                        >
                          <button
                            style={{
                              backgroundColor: "#A855F7",
                              color: "#fff",
                              border: "none",
                              borderRadius: "4px",
                              padding: "8px 12px",
                              cursor: "pointer",
                              fontSize: "14px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <ContentCopyIcon style={{ marginRight: "4px" }} />
                            Copy
                          </button>
                        </CopyToClipboard>
                      </div>
                      {copied && (
                        <Typography
                          color="secondary"
                          style={{
                            marginTop: "8px",
                            fontSize: "12px",
                            fontStyle: "italic",
                          }}
                        >
                          Copied!
                        </Typography>
                      )}
                      <Divider style={{ margin: "16px 0" }} />
                    </Paper>
                  </div>
                ))}
                <div ref={chatEndRef} />
              </div>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            padding: "16px",
            background: "#ffff",
            borderTop: "1px solid #ddd",
          }}
        >
          <TextField
            fullWidth
            placeholder={
              isChatting
                ? "Ask the AI about charts..."
                : "Write your chart data here..."
            }
            multiline
            rows={3}
            variant="outlined"
            value={chartData}
            onChange={handleChartDataChange}
            disabled={loading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleCreateChart}
                    style={{
                      color: "#fff",
                      fontSize: "2rem",
                      background: "#a855f7",
                      padding: 10,
                      borderRadius: "50%",
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <ArrowUpwardIcon size={24} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Drawer>
    </>
  );
};

export default CharBox;

// import React, { useState, useEffect, useRef, useCallback } from "react";
// import {
//   AppBar,
//   Toolbar,
//   Typography,
//   IconButton,
//   Drawer,
//   TextField,
//   InputAdornment,
//   CircularProgress,
//   Paper,
//   Divider,
//   Grid,
//   Button,
//   Box,
// } from "@mui/material";
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// import EditIcon from "@mui/icons-material/Edit";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import CloseIcon from "@mui/icons-material/Close";
// import UploadFileIcon from "@mui/icons-material/UploadFile";
// import CopyToClipboard from "react-copy-to-clipboard";
// import Loading from "../loading/Loading";
// import { marked } from "marked";
// import * as constants from "../../utils/constants/index";
// import api from "../../utils/fetch-data/api";
// import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
// import { db } from "../../config/firebase";
// import {
//   collection,
//   addDoc,
//   getDocs,
//   query,
//   orderBy,
//   where,
//   deleteDoc,
//   doc,
// } from "firebase/firestore";
// import { toast } from "react-toastify";
// import { userID } from "../../utils/getAccessToken";
// import axios from "axios";
// const MIMEType = (file) => {
//   const extension = file.type.split("/")[1];
//   switch (extension) {
//     case "aac":
//       return "audio/aac";
//     case "flac":
//       return "audio/flac";
//     case "mp3":
//       return "audio/mp3";
//     case "m4a":
//       return "audio/m4a";
//     case "mpeg":
//       return "audio/mpeg";
//     case "mpga":
//       return "audio/mpga";
//     case "mp4":
//       return "audio/mp4";
//     case "opus":
//       return "audio/opus";
//     case "pcm":
//       return "audio/pcm";
//     case "wav":
//       return "audio/wav";
//     case "webm":
//       return "audio/webm";
//     case "flv":
//       return "video/x-flv";
//     case "mov":
//       return "video/mov";
//     case "mpeg":
//       return "video/mpeg";
//     case "mpegps":
//       return "video/mpegps";
//     case "mpg":
//       return "video/mpeg";
//     case "webm":
//       return "video/webm";
//     case "wmv":
//       return "video/wmv";
//     case "3gpp":
//       return "video/3gpp";
//     case "pdf":
//       return "application/pdf";
//     default:
//       return "unknown";
//   }
// };
// const CharBox = ({ quill }) => {
//   const [chartData, setChartData] = useState("");
//   const [copied, setCopied] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [geminiChartResponse, setGeminiChartResponse] = useState(null);
//   const [openDrawer, setOpenDrawer] = useState(false);
//   const [chatHistory, setChatHistory] = useState([]);
//   const chatEndRef = useRef(null);
//   const [isChatting, setIsChatting] = useState(false);
//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [uploadedFile, setUploadedFile] = useState(null);
//   const [showUploadedFile, setShowUploadedFile] = useState(false);
//   const [filePath, setFilePath] = useState(null); // State for file path
//   const [MIMEtype, setMIMEtype] = useState(null); // State for MIME type

//   const handleCloseDrawer = () => {
//     setOpenDrawer(false);
//   };

//   const handleChartDataChange = (event) => {
//     setChartData(event.target.value);
//   };

//   const handleCreateChart = useCallback(async () => {
//     try {
//       setLoading(true);
//       let response;
//       if (uploadedFile) {
//         response = await api.post(constants.AI_CHART_ENDPOINT, {
//           prompt: chartData,
//           userID,
//           MIMEtype,
//           filePath,
//         });
//       } else {
//         response = await api.post(constants.AI_CHART_ENDPOINT, {
//           prompt: chartData,
//           userID,
//         });
//       }

//       const data = response.data;
//       setLoading(false);
//       setGeminiChartResponse(data);
//       setChartData("");

//       fetchChatHistory();
//     } catch (error) {
//       console.error("Error asking AI:", error.message);
//       setLoading(false);
//     }
//   }, [chartData, userID, filePath, MIMEtype]);

//   const fetchChatHistory = async () => {
//     try {
//       const q = query(
//         collection(db, "charts"),
//         where("userID", "==", userID),
//         orderBy("timestamp", "asc")
//       );
//       const querySnapshot = await getDocs(q);
//       const history = querySnapshot.docs.map((doc) => doc.data());
//       setChatHistory(history);
//     } catch (error) {
//       toast.error("Error fetching chat history:" || error.message);
//     }
//   };

//   useEffect(() => {
//     fetchChatHistory();
//   }, []);

//   useEffect(() => {
//     if (openDrawer) {
//       chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
//     }
//   }, [chatHistory, openDrawer]);

//   useEffect(() => {
//     if (openDrawer) {
//       setTimeout(() => {
//         chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
//       }, 100);
//     }
//   }, [openDrawer]);

//   const handleInsertChartBelow = () => {
//     if (quill && geminiChartResponse) {
//       const selection = quill.getSelection();
//       quill.clipboard.dangerouslyPasteHTML(
//         selection?.index + selection?.length,
//         marked(geminiChartResponse.text)
//       );
//     }
//   };

//   const clearChatHistory = async () => {
//     try {
//       const q = query(collection(db, "charts"), where("userID", "==", userID));
//       const querySnapshot = await getDocs(q);
//       querySnapshot.forEach((doc) => {
//         deleteDoc(doc.ref);
//       });
//       setChatHistory([]);
//       setIsChatting(false);
//     } catch (error) {
//       toast.error("Error clearing chat history:" || error.message);
//     }
//   };

//   const newChart = async () => {
//     try {
//       const q = query(collection(db, "charts"), where("userID", "==", userID));
//       const querySnapshot = await getDocs(q);
//       querySnapshot.forEach((doc) => {
//         deleteDoc(doc.ref);
//       });
//       setChatHistory([]);
//       setIsChatting(false);
//     } catch (error) {
//       toast.error("Error creating a new chat:" || error.message);
//     }
//   };

//   const handleCopy = () => {
//     setCopied(true);
//     setTimeout(() => setCopied(false), 2000);
//   };

//   const handleUpload = async (file) => {
//     if (!file) return;

//     const fileType = MIMEType(file);

//     if (fileType === "unknown") {
//       toast.error(
//         "Unsupported file type. Please upload a valid audio or video file.",
//         {
//           position: "top-right",
//           autoClose: 5000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "colored",
//         }
//       );
//       return;
//     }

//     setUploadedFile(file);
//     setShowUploadedFile(true);

//     try {
//       // Get pre-signed URL from the backend
//       const response = await api.get(constants.UPLOAD_URL_ENDPOINT, {
//         params: {
//           fileName: file.name,
//           fileType: fileType,
//         },
//       });

//       const { uploadUrl, publicUrl, objectName } = response.data;

//       if (!uploadUrl) {
//         throw new Error("Upload URL is undefined");
//       }

//       // Upload the file to Google Cloud Storage
//       await axios.put(uploadUrl, file, {
//         headers: {
//           "Content-Type": fileType,
//           "Content-Disposition": "inline",
//         },
//         onUploadProgress: (progressEvent) => {
//           const progress = Math.round(
//             (progressEvent.loaded / progressEvent.total) * 100
//           );
//           setUploadProgress(progress);
//         },
//       });

//       // Set filePath and MIMEtype after successful upload
//       setFilePath(objectName); // Assuming `objectName` is the file path in Google Cloud Storage
//       setMIMEtype(fileType);
//     } catch (error) {
//       console.error("Error uploading file:", error);
//       toast.error("Audio upload failed. Please try again.", {
//         position: "top-right",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "colored",
//       });
//     }
//   };

//   const handleCloseUploadedFile = () => {
//     setUploadedFile(null);
//     setShowUploadedFile(false);
//   };

//   return (
//     <>
//       <button className="recorder_button" onClick={() => setOpenDrawer(true)}>
//         <AutoAwesomeIcon />
//         <span> AI Chart (New Input)</span>
//       </button>
//       <Drawer
//         anchor="right"
//         open={openDrawer}
//         onClose={handleCloseDrawer}
//         PaperProps={{
//           style: {
//             minWidth: "40vw",
//             maxWidth: "40vw",
//             textAlign: "center",
//             background: "white",
//             minHeight: "100vh",
//             display: "flex",
//             flexDirection: "column",
//           },
//         }}
//       >
//         <AppBar position="static" style={{ background: "white" }}>
//           <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
//             <Button
//               style={{
//                 background: "red",
//                 color: "white",
//                 padding: "5px 15px",
//               }}
//               onClick={handleCloseDrawer}
//               startIcon={<CloseIcon />}
//             >
//               Close
//             </Button>
//             <div>
//               <Button
//                 style={{
//                   background: "#A855F7",
//                   color: "white",
//                   padding: "5px 15px",
//                 }}
//                 onClick={clearChatHistory}
//               >
//                 Clear Charts
//               </Button>
//               <Button
//                 style={{
//                   background: "#0c8ce9",
//                   color: "white",
//                   padding: "5px 15px",
//                   marginLeft: 10,
//                 }}
//                 color="primary"
//                 onClick={newChart}
//               >
//                 New Chart
//               </Button>
//             </div>
//           </Toolbar>
//         </AppBar>
//         <div style={{ flex: 1, overflow: "auto", padding: "16px" }}>
//           <Grid container spacing={2}>
//             <Grid item xs={12} style={{ textAlign: "right" }}></Grid>
//             <Grid item xs={12}>
//               <div style={{ padding: "16px" }}>
//                 {chatHistory.map((item, index) => (
//                   <div
//                     key={index}
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       marginBottom: "16px",
//                     }}
//                   >
//                     <Paper
//                       elevation={3}
//                       style={{
//                         padding: "16px",
//                         textAlign: "start",
//                         marginLeft: "auto",
//                         backgroundColor: "white",
//                         borderRadius: "8px",
//                         maxWidth: "80%",
//                         boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//                         alignItems: "flex-start",
//                       }}
//                     >
//                       <Typography
//                         variant="body1"
//                         style={{
//                           whiteSpace: "pre-wrap",
//                           fontFamily: "'Roboto', sans-serif",
//                         }}
//                       >
//                         {item.prompt}
//                       </Typography>
//                     </Paper>
//                     <Paper
//                       elevation={3}
//                       style={{
//                         padding: "16px",
//                         textAlign: "start",
//                         marginRight: "auto",
//                         backgroundColor: "#e0f7fa",
//                         borderRadius: "8px",
//                         maxWidth: "80%",
//                         minWidth: "80%",
//                         boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//                         alignItems: "flex-end",
//                         marginTop: 10,
//                       }}
//                     >
//                       <Typography
//                         variant="body1"
//                         style={{
//                           whiteSpace: "pre-wrap",
//                           fontFamily: "'Roboto', sans-serif",
//                         }}
//                         dangerouslySetInnerHTML={{
//                           __html: marked(item.response),
//                         }}
//                       />
//                       <div
//                         style={{
//                           display: "flex",
//                           justifyContent: "space-between",
//                           marginTop: "16px",
//                         }}
//                       >
//                         <button
//                           onClick={handleInsertChartBelow}
//                           style={{
//                             backgroundColor: "#A855F7",
//                             color: "#fff",
//                             border: "none",
//                             borderRadius: "4px",
//                             padding: "8px 12px",
//                             cursor: "pointer",
//                             fontSize: "14px",
//                             display: "flex",
//                             alignItems: "center",
//                           }}
//                         >
//                           <EditIcon style={{ marginRight: "4px" }} />
//                           Insert Below
//                         </button>
//                         <CopyToClipboard
//                           text={geminiChartResponse?.text}
//                           onCopy={handleCopy}
//                         >
//                           <button
//                             style={{
//                               backgroundColor: "#A855F7",
//                               color: "#fff",
//                               border: "none",
//                               borderRadius: "4px",
//                               padding: "8px 12px",
//                               cursor: "pointer",
//                               fontSize: "14px",
//                               display: "flex",
//                               alignItems: "center",
//                             }}
//                           >
//                             <ContentCopyIcon style={{ marginRight: "4px" }} />
//                             Copy
//                           </button>
//                         </CopyToClipboard>
//                       </div>
//                       {copied && (
//                         <Typography
//                           color="secondary"
//                           style={{
//                             marginTop: "8px",
//                             fontSize: "12px",
//                             fontStyle: "italic",
//                           }}
//                         >
//                           Copied!
//                         </Typography>
//                       )}
//                       <Divider style={{ margin: "16px 0" }} />
//                     </Paper>
//                   </div>
//                 ))}
//                 <div ref={chatEndRef} />
//               </div>
//             </Grid>
//           </Grid>
//         </div>
//         <div>
//           {uploadProgress < 100 && uploadProgress > 0 ? (
//             <Box
//               sx={{
//                 border: "1px solid #ccc",
//                 padding: "10px",
//                 borderRadius: "4px",
//                 marginRight: "10px",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: " center",
//                 maxWidth: "95%",
//                 minWidth: "95%",
//                 maxHeight: "40px",
//               }}
//             >
//               <Typography variant="body2" color="textSecondary">
//                 Upload Progress: {uploadProgress}%
//               </Typography>
//             </Box>
//           ) : (
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: " center",
//               }}
//             >
//               {showUploadedFile && (
//                 <Box
//                   sx={{
//                     border: "1px solid #ccc",
//                     padding: "10px",
//                     borderRadius: "4px",
//                     marginRight: "10px",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: " center",
//                     maxWidth: "95%",
//                     minWidth: "95%",
//                     maxHeight: "40px",
//                   }}
//                 >
//                   {uploadedFile.type.startsWith("image/") && (
//                     <img
//                       src={URL.createObjectURL(uploadedFile)}
//                       alt="Uploaded File"
//                       style={{ maxWidth: "50px", maxHeight: "50px" }}
//                     />
//                   )}
//                   <Typography variant="body2" style={{ marginLeft: "10px" }}>
//                     {uploadedFile.name}
//                   </Typography>
//                   <IconButton onClick={handleCloseUploadedFile}>
//                     <CloseIcon />
//                   </IconButton>
//                 </Box>
//               )}
//             </div>
//           )}
//           <div
//             style={{
//               padding: "16px",
//               background: "#ffff",
//               borderTop: "1px solid #ddd",
//               display: "flex",
//               alignItems: "center",
//             }}
//           >
//             <input
//               accept="audio/*,video/*,application/pdf"
//               style={{ display: "none" }}
//               id="upload-audio-file"
//               type="file"
//               onChange={(e) => handleUpload(e.target.files[0])}
//             />
//             <label htmlFor="upload-audio-file">
//               <IconButton
//                 component="span"
//                 style={{
//                   color: "#fff",
//                   fontSize: "2rem",
//                   background: "#a855f7",
//                   padding: 10,
//                   borderRadius: "50%",
//                   marginRight: 10,
//                 }}
//               >
//                 <UploadFileIcon size={24} />
//               </IconButton>
//             </label>

//             <TextField
//               fullWidth
//               placeholder={
//                 isChatting
//                   ? "Ask the AI about charts..."
//                   : "Write your chart data here..."
//               }
//               multiline
//               rows={3}
//               variant="outlined"
//               value={chartData}
//               onChange={handleChartDataChange}
//               disabled={loading}
//               InputProps={{
//                 endAdornment: (
//                   <InputAdornment position="end">
//                     <IconButton
//                       onClick={handleCreateChart}
//                       style={{
//                         color: "#fff",
//                         fontSize: "2rem",
//                         background: "#a855f7",
//                         padding: 10,
//                         borderRadius: "50%",
//                       }}
//                     >
//                       {loading ? (
//                         <CircularProgress size={24} />
//                       ) : (
//                         <ArrowUpwardIcon size={24} />
//                       )}
//                     </IconButton>
//                   </InputAdornment>
//                 ),
//               }}
//             />
//           </div>
//         </div>
//       </Drawer>
//     </>
//   );
// };
// export default CharBox;
