import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Dialog,
  DialogActions,
  Chip,
  Slide,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
} from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { v4 as uuidV4 } from "uuid";
import "./AudioRecorder.css";
import * as constants from "../../utils/constants/index";
import { toast } from "react-toastify";
import Loading from "../loading/Loading";
import { createItem } from "../../utils/fetch-data/FetchData";
import axios from "axios";
import api from "../../utils/fetch-data/api";
import AddIcon from "@mui/icons-material/Add";
import io from "socket.io-client";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AudioRecorder = () => {
  const newAudioId = uuidV4();
  const { id: audioId } = useParams();
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [newRecording, setNewRecording] = useState(true);
  const [generatingSummary, setGeneratingSummary] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [noteTitle, setNoteTitle] = useState("");
  const [isBuffering, setIsBuffering] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const navigate = useNavigate();
  const audioFileInputRef = useRef(null);
  const pdfFileInputRef = useRef(null);
  const videoFileInputRef = useRef(null);

  const mediaRecorderRef = useRef(null);
  const recordingIntervalRef = useRef(null);
  const socketRef = useRef(null);

  const startRecording = async () => {
    try {
      // Initialize WebSocket connection
      socketRef.current = io(constants.BASE_URL, {
        transports: ["websocket"],
        path: "/save-audio",
      });

      // Promise to check WebSocket connection
      const connectionPromise = new Promise((resolve, reject) => {
        socketRef.current.on("connect", () => {
          console.log("WebSocket connected");
          resolve();
        });

        socketRef.current.on("connect_error", (error) => {
          reject(new Error("WebSocket connection failed: " + error.message));
        });

        socketRef.current.on("connect_timeout", () => {
          reject(new Error("WebSocket connection timed out"));
        });

        socketRef.current.on("disconnect", () => {
          console.log("WebSocket disconnected");
        });
      });

      // Wait for WebSocket connection to be established
      await connectionPromise;

      // Handle recording errors
      socketRef.current.on("recordingError", (audioId, errorMessage) => {
        setIsBuffering(false);
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "audio/webm",
      });

      // Emit startRecording event
      socketRef.current.emit("startRecording", audioId);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          // Send audio data chunk to the server via WebSocket
          socketRef.current.emit("audioChunk", {
            audioId,
            chunk: event.data,
          });
        }
      };

      mediaRecorderRef.current.start(1000);

      setIsRecording(true);
      setNewRecording(false);

      recordingIntervalRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } catch (error) {
      // Handle errors related to WebSocket connection and recording
      toast.error("Error starting recording: " + error.message);
    }
  };

  const stopRecording = async () => {
    try {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      if (recordingIntervalRef.current) {
        clearInterval(recordingIntervalRef.current);
        recordingIntervalRef.current = null;
      }
      setRecordingTime(0);

      // Emit an event to indicate the stop of recording
      socketRef.current.emit("stopRecording", audioId);
      socketRef.current.disconnect(); // Disconnect the socket
      // Proceed with uploading the file or any other logic
      const objectName = `public/audios/${audioId}.webm`;

      // Show the upload dialog
      setOpenUploadDialog(true);
      setIsUploading(true);

      await createItem(constants.UPLOAD_AUDIO_ENDPOINT, {
        audioId: audioId,
        fileName: objectName,
        documentType: "audio/webm",
      });
      toast.success("File uploaded successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("Error stopping recording:", error);
      setIsUploading(false);
    }
  };

  const pauseRecording = () => {
    if (mediaRecorderRef.current && isRecording && !isPaused) {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
      clearInterval(recordingIntervalRef.current);
    }
  };

  const resumeRecording = () => {
    if (mediaRecorderRef.current && isRecording && isPaused) {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
      recordingIntervalRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    }
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  const handleAddNote = () => {
    setDialogOpen(true);
  };

  const handleDialogSubmit = () => {
    if (!noteTitle) {
      toast.error("Please fill in the input");
      return;
    }

    // Encode the noteTitle to make it URL-safe
    const encodedNoteTitle = encodeURIComponent(noteTitle);
    navigate(`/document/${newAudioId}/${encodedNoteTitle}/Personal/individual`);
    setDialogOpen(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleNewRecording = () => {
    setNewRecording(false);
    navigate("/");
    navigate(`audio/${newAudioId}`);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setOpenUploadDialog(true);
    await handleUpload(file);
  };

  const handleUpload = async (file) => {
    if (!file) return;

    try {
      // Get pre-signed URL from the backend
      const response = await api.get(constants.UPLOAD_URL_ENDPOINT, {
        params: {
          fileName: file.name,
          fileType: file.type,
        },
      });

      const { uploadUrl, publicUrl, objectName } = response.data;

      if (!uploadUrl) {
        throw new Error("Upload URL is undefined");
      }

      // Upload the file to Google Cloud Storage
      await axios.put(uploadUrl, file, {
        headers: {
          "Content-Type": file.type,
          "Content-Disposition": "inline",
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setUploadProgress(progress);
        },
      });

      // Now you have the download URL for your audio file.
      // Send the download URL to your backend server for processing
      // using the `createItem` function or similar.

      await createItem(constants.UPLOAD_AUDIO_ENDPOINT, {
        audioUrl: publicUrl,
        audioId: audioId,
        fileName: objectName,
        documentType: MIMEType(file),
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success("File uploaded successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.error(
              "Error uploading file. Please try again." ||
                response?.data?.message
            );
            navigate("/");
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error("Error uploading to backend:", error);
          toast.error("Error uploading file. Please try again.");
        });
      navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("Error uploading file:", error);
      setOpenUploadDialog(false);
      toast.error("Audio upload failed. Please try again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      navigate("/");
      window.location.reload();
    }
  };

  const triggerAudioFileInput = () => {
    audioFileInputRef.current.click();
  };

  const triggerPdfFileInput = () => {
    pdfFileInputRef.current.click();
  };

  const triggerVideoFileInput = () => {
    videoFileInputRef.current.click();
  };

  const handleCloseDialog = () => {
    setOpenUploadDialog(false);
    navigate("/");
    window.location.reload();
  };

  const handleGenerateSummary = async (link) => {
    // Check if the link is a YouTube or article link
    let isYouTubeLink = false;
    if (link.includes("youtube.com") || link.includes("youtu.be")) {
      isYouTubeLink = true;
    }

    try {
      setGeneratingSummary(true);

      // Send the appropriate link to the backend for processing
      let response;
      if (isYouTubeLink) {
        response = await createItem(constants.PROCESS_YOUTUBE_ENDPOINT, {
          link: link,
          audioId: audioId,
        });
      } else {
        response = await createItem(constants.PROCESS_ARTICLE_ENDPOINT, {
          link: link,
          audioId: audioId,
        });
      }

      if (response.status === 200) {
        toast.success(
          `${
            isYouTubeLink ? "YouTube" : "Article"
          } link processed successfully`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        navigate("/");
        window.location.reload();
      } else {
        toast.error(
          `Error processing ${
            isYouTubeLink ? "YouTube" : "article"
          } link. Please try again.`
        );
        navigate("/");
        window.location.reload();
      }
    } catch (error) {
      console.error(
        `Error processing ${isYouTubeLink ? "YouTube" : "article"} link:`,
        error
      );
      toast.error(
        `Error processing ${
          isYouTubeLink ? "YouTube" : "article"
        } link. Please try again.`
      );
      navigate("/");
      window.location.reload();
    } finally {
      setGeneratingSummary(false);
    }
  };

  useEffect(() => {
    if (!isRecording && !isPaused && newRecording) navigate("/");
  }, [isRecording, isPaused, newRecording]);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setNewRecording(true);
    }
  }, [isRecording, isPaused, newRecording, location]);

  // Helper function to get the filename based on file type
  const MIMEType = (file) => {
    const extension = file.type.split("/")[1];
    switch (extension) {
      case "aac":
        return "audio/aac";
      case "flac":
        return "audio/flac";
      case "mp3":
        return "audio/mp3";
      case "m4a":
        return "audio/m4a";
      case "mpeg":
        return "audio/mpeg";
      case "mpga":
        return "audio/mpga";
      case "mp4":
        return "audio/mp4";
      case "opus":
        return "audio/opus";
      case "pcm":
        return "audio/pcm";
      case "wav":
        return "audio/wav";
      case "webm":
        return "audio/webm";
      case "flv":
        return "video/x-flv";
      case "mov":
        return "video/mov";
      case "mpeg":
        return "video/mpeg";
      case "mpegps":
        return "video/mpegps";
      case "mpg":
        return "video/mpeg";
      case "mp4":
        return "video/mp4";
      case "webm":
        return "video/webm";
      case "wmv":
        return "video/wmv";
      case "3gpp":
        return "video/3gpp";
      case "pdf":
        return "application/pdf";
      default:
        return "unknown";
    }
  };

  return (
    <div className="audioRecorder">
      <div className="audioComp_header">
        <Typography
          sx={{
            color: "#0C8CE9",
            fontWeight: 800,
            marginBottom: 5,
            textAlign: "center",
          }}
          variant="h3"
        >
          Boost Your Learning Efficiency
        </Typography>
        <p>Record lectures, get summaries, and focus on what matters.</p>
        <div className="audio_recorder">
          <h1>{formatTime(recordingTime)}</h1>
          <div className="recorder_buttons">
            {!isRecording && !isPaused && newRecording && (
              <button
                className="recorder_button"
                onClick={handleNewRecording}
                disabled={isRecording}
              >
                <AddCircleIcon sx={{ fontSize: "2rem", color: "white" }} />
                Generate summary
              </button>
            )}
            {!isRecording && !isPaused && newRecording && (
              <button
                className="recorder_button"
                onClick={handleAddNote}
                disabled={isRecording}
              >
                <AddIcon sx={{ fontSize: "2rem", color: "white" }} />
                Write a summary
              </button>
            )}

            {!isRecording && !isPaused && !newRecording && (
              <button
                className="recorder_button"
                onClick={startRecording}
                disabled={isRecording}
              >
                <MicIcon sx={{ fontSize: "2rem", color: "white" }} />
                Start Recording
              </button>
            )}
            {!isRecording && !isPaused && !newRecording && (
              <>
                <button
                  className="recorder_button"
                  onClick={triggerAudioFileInput}
                >
                  <FileUploadIcon sx={{ fontSize: "2rem", color: "white" }} />
                  Upload an Audio
                </button>
                <input
                  type="file"
                  ref={audioFileInputRef}
                  accept="audio/aac,audio/flac,audio/mp3,audio/m4a,audio/mpeg,audio/mpga,audio/mp4,audio/opus,audio/pcm,audio/wav,audio/webm"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </>
            )}
            {!isRecording && !isPaused && !newRecording && (
              <>
                <button
                  className="recorder_button"
                  onClick={triggerPdfFileInput}
                >
                  <FileUploadIcon sx={{ fontSize: "2rem", color: "white" }} />
                  Upload a Document
                </button>
                <input
                  type="file"
                  ref={pdfFileInputRef}
                  accept=".pdf"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </>
            )}
            {/* {!isRecording && !isPaused && !newRecording && (
              <>
                <button
                  className="recorder_button"
                  onClick={triggerVideoFileInput}
                >
                  <FileUploadIcon sx={{ fontSize: "2rem", color: "white" }} />
                  Upload a Video
                </button>
                <input
                  type="file"
                  ref={videoFileInputRef}
                  accept="video/x-flv,video/mov,video/mpeg,video/mpegps,video/mpg,video/mp4,video/webm,video/wmv,video/3gpp"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </>
            )} */}

            {/* Show Pause button only when recording and not paused */}
            {isRecording && !isPaused && (
              <button
                className="recorder_button"
                onClick={pauseRecording}
                disabled={!isRecording || isPaused}
              >
                <PauseCircleIcon sx={{ fontSize: "2rem", color: "white" }} />
                Pause Recording
              </button>
            )}
            {/* Show Resume button only when recording is paused */}
            {isRecording && isPaused && (
              <button
                className="recorder_button"
                onClick={resumeRecording}
                disabled={!isRecording || !isPaused}
              >
                <PlayCircleIcon sx={{ fontSize: "2rem", color: "white" }} />
                Resume Recording
              </button>
            )}
            {(isRecording || isPaused) && (
              <button
                className="recorder_button stop_button"
                onClick={stopRecording}
                disabled={!isRecording}
              >
                <StopCircleIcon sx={{ fontSize: "2rem", color: "white" }} />
                Stop Recording
              </button>
            )}
          </div>
          {isBuffering && (
            <CircularProgress
              size={30}
              style={{ marginLeft: "10px", color: "blue" }} // Add a buffering indicator
            />
          )}
          <div className="document_inputs">
            {!isRecording && !isPaused && !newRecording && (
              <>
                <div className="link-input-div">
                  <input
                    name="text"
                    autocomplete="off"
                    placeholder="Paste YouTube or Article Link"
                    ref={(input) => {
                      if (input) {
                        input.addEventListener("keyup", (event) => {
                          if (event.key === "Enter") {
                            handleGenerateSummary(input.value);
                          }
                        });
                      }
                    }}
                  />
                  <button
                    className="recorder_button"
                    onClick={() =>
                      handleGenerateSummary(
                        document.querySelector(".link-input-div input").value
                      )
                    }
                  >
                    <AddCircleIcon sx={{ fontSize: "2rem", color: "white" }} />
                    Generate
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Container></Container>

      <Dialog
        open={generatingSummary}
        maxWidth="md"
        fullWidth
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            minHeight: "30vh",
            maxHeight: "80vh",
            textAlign: "center",
            background: "#eff4ff",
          },
        }}
      >
        {generatingSummary && (
          <Loading
            normalLoading={true}
            tittle={"Generating summary. Please wait..."}
          />
        )}

        <DialogActions sx={{ bgcolor: "#f3e5f5" }}>
          <Chip
            onClick={() => setGeneratingSummary(false)}
            label="Close"
            style={{
              backgroundColor: "red",
              color: "white",
              cursor: "pointer",
              fontSize: "1rem",
            }}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUploadDialog}
        maxWidth="md"
        fullWidth
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            minHeight: "30vh",
            maxHeight: "80vh",
            textAlign: "center",
            background: "#eff4ff",
          },
        }}
      >
        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          {isUploading && (
            <Loading
              normalLoading={true}
              tittle={"Uploading File... Please wait..."}
            />
          )}
          {!isUploading && uploadProgress === 100 && (
            <Loading
              normalLoading={false}
              tittle={"Your file is being processed. Please wait..."}
            />
          )}
        </div>
        <DialogActions sx={{ bgcolor: "#f3e5f5" }}>
          <Chip
            onClick={() => handleCloseDialog()}
            label="Close"
            style={{
              backgroundColor: "red",
              color: "white",
              cursor: "pointer",
              fontSize: "1rem",
            }}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUploadDialog}
        maxWidth="md"
        fullWidth
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            minHeight: "30vh",
            maxHeight: "80vh",
            textAlign: "center",
            background: "#eff4ff",
          },
        }}
      >
        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          {uploadProgress < 100 && (
            <>
              <Loading
                normalLoading={true}
                tittle={`Uploading File... (${uploadProgress}%)`}
              />
            </>
          )}
          {uploadProgress === 100 && (
            <Loading
              normalLoading={false}
              tittle={"Your file is being processed. Please wait..."}
            />
          )}
        </div>
        <DialogActions sx={{ bgcolor: "#f3e5f5" }}>
          <Chip
            onClick={() => handleCloseDialog()}
            label="Close"
            style={{
              backgroundColor: "red",
              color: "white",
              cursor: "pointer",
              fontSize: "1rem",
            }}
          />
        </DialogActions>
      </Dialog>
      {/* Dialog for adding a new note */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogTitle>Add New Note</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="noteTitle"
            label="Note Title"
            fullWidth
            variant="outlined"
            value={noteTitle}
            onChange={(e) => setNoteTitle(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <button onClick={handleDialogClose} className="dialogue_cancel">
            Cancel
          </button>
          <button onClick={handleDialogSubmit} className="dialogue_success">
            Submit
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AudioRecorder;
